const costCenterZh = {
  submitSuccess: '提交成功',
  // rechargeRecord
  outTradeNo: '商户订单号',
  inputPlaceholder: '请输入',
  payType: '支付类型',
  wechat: '微信',
  alipay: '支付宝',
  background: '后台',
  selectPlaceholder: '请选择',
  payStatus: '支付状态',
  success: '成功',
  failure: '失败',
  phone: '用户手机号',
  createTime: '创建时间',
  search: '搜索',
  export: '导出',
  chargeMoney: '支付金额',
  transactionId: '支付订单号',
  successTime: '完成时间',
  // order
  taskOrder: '实例订单',
  dataStorageOrder: '数据存储订单',
  netOrder: '网络带宽订单',
  number: '订单号',
  taskName: '实例名称',
  storageVolumeName: '存储卷名称',
  bindPhone: '绑定用户手机号',
  status: '状态',
  paying: '计费中',
  payFinished: '支付完成',
  payFail: '支付失败',
  compensation: '赔付',
  gpuTask: '运算卡实例',
  dataStorage: '数据存储',
  net: '网络带宽',
  billingType: '计费类型',
  accordingTimeout: '按时按量',
  accordingDays: '包天',
  accordingMonth: '包月',
  accordingYear: '包年',
  createTask: '创建实例',
  relet: '续租',
  webDiskExpansion: '网盘扩容(一次性扣费)',
  cacheDiskExpansion: '缓存盘扩容（实时计费）',
  cacheDiskReduction: '缓存盘缩容（实时计费）',
  networkBandwidthExpansion: '网络带宽扩容',
  networkBandwidthReduction: '网络带宽缩容',
  billingChange: '计费变更',
  taskAssignment: '实例升配',
  claimAmount: '赔付金额',
  remark: '说明',
  none: '无',
  volumeId: '存储卷ID',
  InstanceId: '实例ID',
  orderPrice: '订单金额',
  nodeIp: '节点IP',
  // coupon
  couponName: '优惠券名称',
  couponType: '优惠券类型',
  couponStatus: '优惠券状态',
  coupon: '优惠券',
  voucher: '代金券',
  productType: '产品类型',
  cacheDisk: '缓存盘',
  netDisk: '网盘',
  netWork: '网络',
  add: '添加',
  lock: '已冻结',
  unlock: '已解冻',
  freeze: '冻结',
  unfreeze: '解冻',
  freezeSet: '冻结/解冻',
  used: '已使用',
  expired: '已过期',
  couponRule: '规则',
  verify: '验证',
  couponFull: '满减额度',
  expireTime: '失效时间',

  // invoice
  invoiceTotal: '发票总额',
  invoiceTitle: '发票抬头',
  taxpayerNumber: '纳税人识别号',
  invoiceStatus: '发票状态',
  applyTime: '申请时间',
  review: '审核中',
  mailed: '已邮寄',
  repeal: '已撤销',
  rejectd: '已驳回',
  rejected: '撤回',
  across: '通过',
  reject: '驳回',
  detail: '详情',
  notice: '提示',
  auditOpinion: '审核意见',
  invoiceInformation: '发票信息',
  invoiceType: '发票类型',
  invoiceMoney: '发票金额',
  billingMethod: '开票方式',
  bankName: '开户银行名称',
  bankAccount: '基本开户账号',
  companyAddress: '注册场所地址',
  companyPhone: '注册固定电话',
  userName: '所属用户',
  rejection: '驳回原因',
  personalInformation: '个人信息',
  consignee: '姓名',
  consigneePhone: '电话',
  consigneeAddress: '地址',
  consigneeEmail: '电子邮箱',
  VATordinaryinvoice: '增值税普通发票',
  VATspecialinvoice: '增值税专用发票',
  electronicinvoice: '电子发票',
  postInvoice: '邮寄发票',
  invoicePhone: '手机号',

  // discountScheme
  schemeName: '方案名称',
  edit: '编辑',
  del: '删除',
  discountProgramHours: '用户折扣方案(小时)',
  discountProgramDay: '用户折扣方案(天)',
  discountProgramMonth: '用户折扣方案(月)',
  discountProgramYear: '用户折扣方案(年)',
  comment: '备注',

  // product-pricing
  areaId: '区域名称',
  deviceId: '设备ip',
  gpuModelId: '运算卡型号',
  productName: '产品名称',
  storageName: '存储名称',
  storageTypeId: '存储类型',
  freeCapacity: '免费容量(G)',
  priceHour: '产品单价(G/时)',
  discountSchemeId: '折扣方案',
  upBandwidth: '上行带宽(M/s)',
  downBandwidth: '下行带宽(M/s)',

  // recharge
  tipsOne: '交易将在',
  tipsTwo: '后关闭，请及时付款！',
  money: '交易金额',
  scanTips: '请使用微信扫描下方二维码支付',
  reset: '重 置',
  successPayTip: '我已支付完成',
  paySuccess: '支付成功',
  payPrice: '充值金额',
  currentBalace: '当前金额',
  checkDetail: '查看详情',
  back: '返 回',
  accountBalace: '账户余额',
  otherBalance: '其他金额',
  chargeMoneyPlaceholder: '请输入充值额度',
  payWay: '支付方式',
  wechatPay: '微信支付',
  recharge: '充 值',
  rechargeTipsOne: '只可充值50000以下的金额',
  rechargeTipsTwo: '只可输入两位小数以下或正整数的金额',
  rechargeTipsThree: '请输入充值金额',
  rechargeUnpaidTips: '当前订单交易未支付，将自动返回充值界面',
  rechargeOverTimeTips: '当前订单交易已超时，将自动返回充值界面',
  // myInvoice
  invoicable: '可开票',
  invoicableMoney: '可开票金额',
  cumulativeRecharge: '累计充值',
  rechargeInvoiced: '充值已开票',
  invoiceMsg: '发票信息',
  vat: '增值税',
  ordinaryInvoice: '普通发票',
  specialInvoice: '专用发票',
  invoiceTips: '暂无发票信息',
  goAdd: '去添加',
  invoiceRecord: '开票记录',
  applied: '已申请',
  invoicedOut: '已开票',
  appliedAgain: '申请开票',
  openVoice: '开票',
  openVoiceMoney: '开票金额',
  goOpenInvoices: '去开票',
  fanhui: '返回',
  confirm: '确认',
  command: '备注',
  messageWarningOne: '请输入发票金额，金额须大于0元',
  messageWarningTwo: '输入金额大于可开票金额',
  messageWarningThree: '请完善开票信息',
  messageSuccess: '申请成功，请等待审批',
  // orderDetail
  basic: '基本信息',
  orderType: '订单类型',
  orderStatus: '订单状态',
  createOrderTime: '订单创建时间',
  paid: '已付费',
  unpaid: '未付费',
  billingMode: '计费方式',
  failedReason: '订单失败原因',
  taskMsg: '实例信息',
  resourceMsg: '资源信息',
  yuan: '元',
  uplinkRate: '上行速率',
  downlinkRate: '下行速率',
  billMsg: '账单信息',

  // market
  marketTips: '严禁挖矿，一经发现封号不退！',
  region: '区域',
  cleanSearch: '清空筛选',
  refining: '细化',
  gpuNum: '运算卡数',
  videoMemory: '运算卡显存（GB）',
  gpuPrice: '运算卡价格（元）',
  modelOptions: '算力型号',
  all: '全部',
  unusedGpuAmount: '空闲运算卡数量',
  memory: '内存',
  videoStorage: '显存',
  hardDisk: '硬盘',
  cacheDiskMaxLimit: '缓存盘',
  cudaMaxVersion: 'Toolkit版本',
  floatingPointComputingPower: '浮点算力',
  priceUnit: '元/时',
  discount: '折',
  discountTips: '会员最低享',
  rentCards: '卡可租',
  AllRent: '已租完',
  accordingTimes: '按量计费',
  accordingDayMonth: '包天包月',
  gpuLaveCount: '空闲运算卡',
  rentTimePlaceHolder: '请选择租用时长',
  core: '核',
  perHour: '/时',
  perDay: '/日',
  perMonth: '/月',
  gpuAmount: '选择运算卡数量',
  taskSpecification: '实例规格',
  platImage: '平台镜像',
  myImage: '我的镜像',
  changeMyImage: '切换为我的镜像',
  changePlatImage: '切换为平台镜像',
  full: '满',
  canUse: '可用',
  taskMsgTips: '运算卡实例 产品可用',
  dailyFee: '日常费用',
  moneyUnits: '元/日',
  configFee: '配置费用',
  membershipDiscount: '我的会员折扣',
  formula: '价格计算公式：(原价x运算卡数量x使用时间x会员折扣)-优惠券优惠金额',
  costDetail: '费用明细',
  noMoneyTips: '余额不足去充值',
  cancel: '取消',
  createOrder: '立即创建',
  noticeMsg: '通知消息',
  clickRead: '一键已读',
  platformArchitecture: '架构',
  cpuModel: 'CPU型号',
  cpuCore: 'CPU核心',
  rules: {
    claimAmount: '请输入赔付金额',
    couponName: '请输入优惠券名称',
    couponType: '请选择优惠券类型',
    productType: '请选择产品类型',
    couponRule: '请输入规则',
    phone: '请输入绑定用户手机号',
    expireTime: '请输入失效时间',
    couponFull: '请输入满减额度',

    invoiceType: '请选择发票类型',
    invoiceTitle: '请输入发票抬头',
    invoiceTitleRule: '请输入正确的发票抬头',
    taxpayerNumber: '请输入纳税人识别号',
    taxpayerNumberRule: '请输入正确的纳税人识别号',
    bankName: '请输入开户行名称',
    companyAddress: '请输入注册场所地址',
    consignee: '请输入姓名',
    consigneeAddress: '请输入地址',
    consigneeEmail: '请输入电子邮件',
    consigneeEmailRule: '邮箱格式错误',
    consigneePhone: '请输入电话',
    consigneePhoneRule: '请输入正确的电话',
    bankAccount: '请输入开户账号',
    bankAccountRule: '请输入正确的开户账号',
    companyPhone: '请输入注册固定电话',
    companyPhoneRule: '请输入正确的注册固定电话',
    schemeName: '请输入方案名称',

    areaId: '请选择区域',
    gpuModelId: '请选择运算卡型号',
    deviceId: '请选择设备',
    discountSchemeId: '请选择折扣方案',
    productName: '请选择产品名称',
    storageName: '请输入存储名称',
    storageTypeId: '请选择存储类型',
    freeCapacity: '请输入免费容量',
    priceHour: '请输入产品单价',

    image: '请输入镜像',
    suggestion: '请输入审核意见',

    upBandwidth: '请输入上行带宽',
    downBandwidth: '请输入下行带宽'
  }
}
const costCenterEn = {
  submitSuccess: ' Submit successfully ',
  // rechargeRecord
  outTradeNo: 'Merchant Order number',
  inputPlaceholder: 'Please enter',
  payType: 'payment type',
  wechat: 'wechat',
  alipay: 'Alipay',
  background: 'background',
  selectPlaceholder: 'Please select',
  payStatus: 'Payment status',
  success: 'Success',
  failure: 'failure',
  phone: 'phone number',
  createTime: 'Create time',
  search: 'Search',
  export: 'export',
  chargeMoney: 'payPrice',
  transactionId: 'Payment Order Number ',
  successTime: 'Completion time',
  // order
  taskOrder: 'Task Order',
  dataStorageOrder: 'Data store order',
  netOrder: 'Network bandwidth order',
  number: 'Order number',
  taskName: 'Task name',
  storageVolumeName: 'Storage volume name',
  bindPhone: "Owning user's phone",
  status: 'status',
  paying: 'in charge',
  payFinished: 'completed',
  payFail: 'failure',
  compensation: 'pay',
  gpuTask: 'GPU task',
  dataStorage: 'Data storage',
  net: 'Network bandwidth',
  billingType: 'billingType',
  accordingTimeout: 'By the hour',
  accordingDays: 'accordingdays',
  accordingMonth: 'accordingmonth',
  accordingYear: 'Package year',
  createTask: 'Create task',
  relet: 'to renew the lease',
  webDiskExpansion: 'Web disk expansion (one-time deduction)',
  cacheDiskExpansion: 'Cache disk expansion (Real-time charging)',
  cacheDiskReduction: 'Cache disk Reduction (real-time charging)',
  NetworkBandwidthExpansion: 'network bandwidth capacity',
  NetworkBandwidthReduction: 'network bandwidth reduction capacity',
  billingChange: 'billingChange',
  taskAssignment: 'taskAssignment',
  claimAmount: 'The amount to be paid',
  remark: 'Remark',
  none: 'none',
  volumeId: 'Storage volume ID',
  InstanceId: 'Instance ID',
  orderPrice: 'orderPrice',
  nodeIp: 'nodeIp',
  // coupon
  couponName: 'Coupon name',
  couponType: 'Coupon type',
  couponStatus: 'Coupon status',
  coupon: 'coupon',
  voucher: 'Voucher',
  productType: 'Product type',
  cacheDisk: 'Cache disk',
  netDisk: 'web disk',
  netWork: 'Network',
  add: 'add',
  lock: 'freeze',
  unlock: 'unfreeze',
  used: 'used',
  expired: 'has expired',
  couponRule: 'Rule',
  verify: 'verify',
  couponFull: 'full reduction',
  expireTime: 'expireTime',
  freeze: 'freeze',
  unfreeze: 'unfreeze',
  freezeSet: 'freeze/unfreeze',
  // invoice
  invoiceTotal: 'invoicetotal ',
  invoiceTitle: 'Invoice title',
  taxpayerNumber: 'TaxpayerNumber',
  invoiceStatus: 'Invoice status',
  applyTime: 'request time',
  review: 'under review',
  mailed: 'mailed',
  repeal: 'repealed',
  rejectd: 'Rejected',
  rejected: 'rejected',
  across: 'through',
  reject: 'Reject',
  detail: 'detail',
  notice: 'Notice',
  auditOpinion: 'Audit comments',
  invoiceInformation: 'Invoice information',
  invoiceType: 'invoice type',
  invoiceMoney: 'invoice amount',
  billingMethod: 'Billing method',
  bankName: 'BankName',
  bankAccount: 'bankAccount',
  companyAddress: 'companyAddress',
  companyPhone: 'companyPhone',
  userName: 'owning user',
  rejection: 'Reject the cause',
  personalInformation: 'Personal information',
  consignee: 'Name',
  consigneePhone: 'Telephone',
  consigneeAddress: 'Address',
  consigneeEmail: 'Email box',
  VATordinaryinvoice: 'VATordinaryinvoice',
  VATspecialinvoice: 'VATspecial Invoice',
  electronicinvoice: 'electronicinvoice',
  postInvoice: 'Mail an invoice',
  invoicePhone: 'Phone',

  // discountScheme
  schemeName: 'Name',
  edit: 'Edit',
  del: 'Delete',
  discountProgramHours: 'User Discount Program (hours)',
  discountProgramDay: 'User Discount Program (days)',
  discountProgramMonth: 'User Discount Program (month)',
  discountProgramYear: 'User Discount Program (Year)',
  comment: 'Remark',
  // product-pricing
  areaId: 'Area name',
  deviceId: 'device IP',
  gpuModelId: 'GPU Model',
  discountSchemeId: 'Discount Scheme',
  productName: 'Product name',
  storageName: 'Storage name',
  storageTypeId: 'Storage type',
  freeCapacity: 'Free Capacity (G)',
  priceHour: 'Unit price (G/ hour)',
  upBandwidth: 'upBandwidth(M/s)',
  downBandwidth: 'downBandwidth(M/s)',

  // recharge
  tipsOne: 'The deal will be in',
  tipsTwo: 'After closing, please pay promptly!',
  money: 'transaction amount',
  scanTips: 'Please use wechat to pay by scanning the QR code below',
  reset: 'reset',
  successPayTip: 'I have paid completed',
  paySuccess: 'success',
  payPrice: 'recharge amount',
  currentBalace: 'current amount',
  checkDetail: 'See details',
  back: 'back',
  accountBalace: 'Account balance',
  otherBalance: 'other amount',
  chargeMoneyPlaceholder: 'Please enter the recharge amount',
  payWay: 'payment method',
  wechatPay: 'wechat',
  recharge: 'recharge',
  rechargeTipsOne: 'Only up to 50000 ',
  rechargeTipsTwo: 'Only amounts less than two decimal places or positive integers can be entered ',
  rechargeTipsThree: ' Please enter the recharge amount ',
  rechargeUnpaidTips: ' If the current order transaction is not paid, it will automatically return to the recharge interface ',
  rechargeOverTimeTips: ' The current order transaction has timed out, will automatically return to the recharge interface ',
  // myInvoice
  invoicable: 'Invoicablemoney',
  invoicableMoney: 'Invoicablemoney',
  cumulativeRecharge: 'Cumulative recharge',
  rechargeInvoiced: 'Recharge has been invoiced',
  invoiceMsg: 'Invoice information',
  vat: 'value-added tax',
  ordinaryInvoice: 'ordinary invoice',
  specialInvoice: 'special invoice',
  invoiceTips: 'No invoice information yet',
  goAdd: 'go to add',
  invoiceRecord: 'invoicing record',
  applied: 'applied',
  invoicedOut: 'invoiced',
  appliedAgain: 'Apply for billing',
  openVoice: 'Invoicing',
  openVoiceMoney: 'Invoiced amount',
  goOpenInvoices: 'Go to invoice',
  fanhui: 'Back',
  confirm: 'confirm',
  command: 'remark',
  messageWarningOne: 'Please enter the invoice amount, the amount must be greater than 0 yuan ',
  messageWarningTwo: 'The input amount is greater than the invoicing amount ',
  messageWarningThree: 'Please improve the billing information ',
  messageSuccess: ' Application is successful, please wait for approval ',
  // orderDetail
  basic: 'Basic information',
  orderType: 'Order type',
  orderStatus: 'Order status',
  createOrderTime: 'createTime',
  paid: 'Paid',
  unpaid: 'unpaid',
  billingMode: 'Billing mode',
  failedReason: 'Cause of order failure',
  taskMsg: 'task information',
  resourceMsg: 'Resource Information',
  yuan: 'Yuan',
  uplinkRate: 'Uplinkrate',
  downlinkRate: 'downlinkRate',
  billMsg: 'Billing Information',

  // market
  marketTips: 'Mining is strictly prohibited, once found, no return!',
  region: 'region',
  cleanSearch: 'Clear filter',
  refining: 'elaboration',
  gpuNum: 'GPU number',
  videoMemory: 'GPU Video Memory (GB)',
  gpuPrice: 'GPU price (yuan)',
  modelOptions: 'Model',
  all: 'All',
  unusedGpuAmount: 'Number of free Gpus',
  memory: 'memory',
  videoStorage: 'Video memory',
  hardDisk: 'hard disk',
  cacheDiskMaxLimit: 'Cache disk',
  cudaMaxVersion: 'Supports the Toolkit version',
  floatingPointComputingPower: 'floating work force',
  priceUnit: 'yuan/hour',
  discount: 'discount',
  discountTips: 'Member minimum',
  rentCards: 'Cards are available for rent',
  AllRent: 'all rented out',
  accordingTimes: ' Charge by volume',
  accordingDayMonth: 'Pay day and month',
  gpuLaveCount: ' idle GPU',
  rentTimePlaceHolder: 'Please select rental duration ',
  core: ' core ',
  perHour: '/ hour ',
  perDay: '/ day ',
  perMonth: '/ month ',
  gpuAmount: ' Select the number of Gpus ',
  taskSpecification: ' Task specification ',
  platImage: ' Platform mirroring ',
  myImage: ' My mirror image ',
  changeMyImage: ' Switch to my image ',
  changePlatImage: ' Switch to platform image ',
  full: ' Full ',
  canUse: ' available ',
  taskMsgTips: 'GPU task product available ',
  dailyFee: ' daily fee ',
  moneyUnits: ' Yuan/day ',
  configFee: ' configuration fee ',
  membershipDiscount: ' My membership discount ',
  formula:
    ' Price calculation formula: (original price xGPU quantity x usage time x member discount)- coupon discount amount ',
  costDetail: ' Cost detail ',
  noMoneyTips: ' Not enough to top up ',
  cancel: ' cancel ',
  createOrder: ' Create now ',
  noticeMsg: ' Notice message ',
  clickRead: ' One click read ',
  platformArchitecture: 'platformArchitecture',
  cpuModel: 'CPU model',
  cpuCore: 'Number of CPU cores',
  rules: {
    claimAmount: ' Please enter the amount to be paid ',
    couponName: ' Please enter the coupon name ',
    couponType: ' Please select coupon type ',
    productType: ' Please select a product type ',
    couponRule: ' Please enter a rule ',
    phone: "Please enter the binding user's mobile phone number ",
    expireTime: ' Please enter expireTime',
    couponFull: ' Please enter the full reduction amount ',

    invoiceType: ' Please select the invoice type ',
    invoiceTitle: ' Please enter the invoice title ',
    invoiceTitleRule: ' Please enter the correct invoice title ',
    taxpayerNumber: ' Please enter Taxpayer Identification Number ',
    taxpayerNumberRule:
      ' Please enter the correct taxpayer identification number ',
    bankName: ' Please enter bank name ',
    companyAddress: ' Please enter registered landline ',
    consignee: ' Please enter the name ',
    consigneeAddress: ' Please enter the address ',
    consigneeEmail: ' Please enter email ',
    consigneeEmailRule: ' Mailbox format is incorrect ',
    consigneePhone: ' Please enter phone number ',
    consigneePhoneRule: ' Please enter the correct phone ',
    bankAccount: ' Please enter your account number ',
    bankAccountRule: ' Please enter the correct account number ',
    companyPhone: ' Please enter phone number',
    companyPhoneRule: ' Please enter the correct phone ',
    schemeName: ' Please enter a scheme name ',

    areaId: ' Please select region ',
    gpuModelId: ' Please select GPU model ',
    deviceId: ' Please select device ',
    discountSchemeId: ' Please select a discount scheme ',
    productName: ' Please select a product name ',
    storageName: ' Please enter a storage name ',
    storageTypeId: ' Please select storage type ',
    freeCapacity: ' Please enter Free capacity ',
    priceHour: ' Please enter the unit price of the product ',

    image: ' Please enter image ',
    suggestion: 'Please enter suggestion',
    upBandwidth: ' Please enter uplink bandwidth',
    downBandwidth: ' Please enter the downstream bandwidth'
  }
}
export { costCenterZh, costCenterEn }
