import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-338e8814"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["xlink:href"]
const _hoisted_2 = ["x", "y"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", null, [
    _createElementVNode("use", { "xlink:href": _ctx.iconName }, null, 8, _hoisted_1),
    _createElementVNode("text", {
      x: _ctx.props.iconPosition.xPosition,
      y: _ctx.props.iconPosition.yPosition,
      style: _normalizeStyle(_ctx.props.iconStyle)
    }, _toDisplayString(_ctx.props.iconText), 13, _hoisted_2)
  ]))
}