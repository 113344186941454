import { App, h, render } from 'vue'
import { Spin } from 'ant-design-vue'
const loading = {
  mounted (el: Element, binding: { value: boolean }) {
    const { value } = binding
    if (value) {
      const vnode = h(
        'div',
        {
          class: 'loading',
          style: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0px',
            left: '0px',
            backgroundColor: '#ffffff',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }
        },
        [
          h(
            Spin
          )
        ]
      )
      render(vnode, el)
    }
  },
  updated (el: Element, binding: { value: boolean }) {
    if (!binding.value) {
      if (el.querySelector('.loading')) {
        (el.querySelector('.loading') as Element).remove()
      }
    } else {
      const vnode = h(
        'div',
        {
          class: 'loading',
          style: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0px',
            left: '0px',
            backgroundColor: '#ffffff',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }
        },
        [
          h(
            Spin
          )
        ]
      )
      render(vnode, el)
    }
  }
}
const install = (Vue: App) => {
  Vue.directive('loading', loading)
}

export default install
