import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/portal'
  },
  {
    path: '/portal',
    component: () => import('@/views/portal/Portal.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register/Register.vue')
  },
  {
    path: '/find-password',
    name: 'FindPassword',
    component: () => import('@/views/find-password/FindPassword.vue')
  },
  {
    path: '/sub-user-login',
    name: 'SubUserLogin',
    component: () => import('@/views/sub-user-login/SubUserLogin.vue')
  },
  {
    path: '/set-user-information',
    name: 'SetUserInformation',
    component: () => import('@/views/set-user-information/SetUserInformation.vue')
  },
  {
    path: '/market',
    name: 'Market',
    component: () => import('@/views/market/Market.vue')
  },
  {
    path: '/market-detail',
    name: 'MarketDetail',
    component: () => import('@/views/market/MarketDetail.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
