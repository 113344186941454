import { defineStore } from 'pinia'
import {
  loginApi,
  loginByCodeApi,
  msgLoginByCodeApi,
  msgLoginApi,
  subUserLoginCodeApi,
  subUserLoginApi
} from '@/api/login'
import { encrtpt } from '@/utils/jsencrypt'
import { PhoneInfoFace } from '@/interface/loginInerface'

interface StateFace {
  token: string;
  themeColor: number;
}
interface UserInfo {
  account: string
  password: string
  codeId?: string
  codeNum?: string
}
export const userStore = defineStore('user', {
  state: ():StateFace => ({
    token: '',
    themeColor: localStorage.getItem('themeColor') ? parseInt(localStorage.getItem('themeColor') as string) : 0
  }),
  actions: {
    login (userInfo: UserInfo, codeFlag: boolean, createCancelList: any): Promise<any> {
      return new Promise((resolve, reject) => {
        if (codeFlag) {
          const params = {
            ...userInfo,
            password: encrtpt(userInfo.password) as string
          }
          loginByCodeApi(params, createCancelList).then(res => {
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        } else {
          const params = {
            ...userInfo,
            password: encrtpt(userInfo.password) as string
          }
          loginApi(params, createCancelList).then(res => {
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        }
      })
    },
    smsLogin (phoneInfo: PhoneInfoFace, codeFlag: boolean): Promise<any> {
      return new Promise((resolve, reject) => {
        if (codeFlag) {
          msgLoginByCodeApi(phoneInfo).then(res => {
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        } else {
          msgLoginApi(phoneInfo).then(res => {
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        }
      })
    },
    subUserLogin (userInfo: UserInfo, codeFlag: boolean): Promise<any> {
      return new Promise((resolve, reject) => {
        if (codeFlag) {
          const params = {
            ...userInfo,
            password: encrtpt(userInfo.password) as string
          }
          subUserLoginCodeApi(params).then(res => {
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        } else {
          const params = {
            ...userInfo,
            password: encrtpt(userInfo.password) as string
          }
          subUserLoginApi(params).then(res => {
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        }
      })
    },
    setThemeColor (data: number) {
      this.themeColor = data
    }
  }
})
