import http from '@/utils/http'
import { LoginParamsFace, PhoneInfoFace, TokenFace, userInfoFace } from '@/interface/loginInerface'
interface Response {
  [propName: string]: any
}

// 校验用户登录项配置
const checkLoginRuleApi = (): Promise<Response> => {
  return http({
    method: 'get',
    url: '/user/userLogin/checkLogin'
  })
}

// 查询登录验证码
const queryImgCodeApi = (): Promise<Response> => {
  return http({
    method: 'get',
    url: '/user/userLogin/imgCode'
  })
}

// 正常登录
const loginApi = (data:LoginParamsFace, fn: any): Promise<Response> => {
  return http({
    method: 'post',
    url: '/user/userLogin/login',
    data: data,
    cancelToken: fn()
  })
}

// 验证码登录
const loginByCodeApi = (data:LoginParamsFace, fn: any): Promise<Response> => {
  return http({
    method: 'post',
    url: '/user/userLogin/loginCode',
    data: data,
    cancelToken: fn()
  })
}

// 子用户验证码登录
const subUserLoginCodeApi = (data: LoginParamsFace) => {
  return http({
    method: 'post',
    url: '/user/userSubLogin/loginCode',
    data: data
  })
}

// 子用户正常登录
const subUserLoginApi = (data: LoginParamsFace) => {
  return http({
    method: 'post',
    url: '/user/userSubLogin/login',
    data: data
  })
}

// 获取手机验证码
const sendPhoneCodeApi = (data: string): Promise<Response> => {
  return http.request({
    method: 'post',
    url: `/user/userLogin/sendPhoneMsg/${data}`
  })
}

// 手机短信验证码登录
const msgLoginByCodeApi = (data:PhoneInfoFace): Promise<Response> => {
  return http({
    method: 'post',
    url: '/user/userLogin/msgCodeLogin',
    data: data
  })
}

// 手机短信登录
const msgLoginApi = (data:PhoneInfoFace): Promise<Response> => {
  return http({
    method: 'post',
    url: '/user/userLogin/msgLogin',
    data: data
  })
}

// 用户强制登录
const forceLoginApi = (data: TokenFace): Promise<Response> => {
  return http({
    method: 'post',
    url: '/user/userLogin/forceLogin',
    data: data
  })
}

// 用户登出
const logOutApi = (): Promise<Response> => {
  return http({
    method: 'post',
    url: 'user/exit/logout'
  })
}

// 用户密码校验
const checkPasswordApi = () => {
  return http({
    method: 'get',
    url: '/user/userLogin/checkPassword'
  })
}

// 完善用户信息
const setUserInfoApi = (data: userInfoFace) => {
  return http({
    method: 'post',
    url: '/user/userLogin/userSupplement',
    data: data
  })
}

// 扫码登录
const qrcodeApi = () => {
  return http({
    method: 'get',
    url: '/user/userLogin/qrcode'
  })
}

// 实时判断用户是否扫码
const qrcodeQueryApi = (sceneCode: string) => {
  return http.request({
    method: 'post',
    url: `/user/userLogin/qrcodeQuery/${sceneCode}`
  })
}

export {
  checkLoginRuleApi,
  queryImgCodeApi,
  loginApi,
  loginByCodeApi,
  sendPhoneCodeApi,
  msgLoginByCodeApi,
  msgLoginApi,
  forceLoginApi,
  logOutApi,
  checkPasswordApi,
  subUserLoginCodeApi,
  subUserLoginApi,
  setUserInfoApi,
  qrcodeApi,
  qrcodeQueryApi
}
