import { maintenanceCenterEn } from './module/maintenance-center'
import { costCenterEn } from './module/cost-center'
import { addTaskEn } from './module/add-task'
import { myTaskEN } from './module/mytask'
import { netDiskEn } from './module/net-disk'
import { imageEn } from './module/image'
import { delEn } from './module/del'
import { userCenterEn } from './module/user-center'
import { stationMessageEn } from './module/station-message'
import { workOrderEn } from './module/work-order'
import { addEn } from './module/add'
import { subUserManagementEn } from './module/sub-user-management'
import { headNavigationEN } from './module/head-navigation'
import { loginEN } from './module/login'
export default {
  maintenanceCenter: maintenanceCenterEn,
  costCenter: costCenterEn,
  addTask: addTaskEn,
  myTask: myTaskEN,
  netDisk: netDiskEn,
  image: imageEn,
  del: delEn,
  userCenter: userCenterEn,
  stationMessage: stationMessageEn,
  workOrder: workOrderEn,
  add: addEn,
  subUserManagement: subUserManagementEn,
  headNavigation: headNavigationEN,
  login: loginEN
}
