const addTaskCn = {
  region: '区域',
  resourceSpecifications: '资源规格',
  name: '规格名称',
  node: '计算节点',
  availableQuantity: '可用数量',
  price: '价格',
  runningMode: '运行模式',
  standAlone: '单机',
  multiMachine: '多机',
  resourceConfig: '资源配置',
  memory: '内存',
  systemDisk: '系统盘',
  networkBandwidth: '网络带宽',
  cacheDisk: '缓存盘',
  SpecificationQuantity: '规格数量',
  platformImage: '平台镜像',
  myImage: '我的镜像',
  submit: '提交',
  task: '实例',
  change: '切换为',
  billingChanges: '计费变更',
  chargeType: '计费类型',
  orderDiscount: '订单折扣',
  orderPrice: '订单价格',
  usageDuration: '使用时长',
  orderOriginalPrice: '订单原价',
  chargeTypePlaceHolder: '请选择计费类型',
  day: '包天',
  month: '包月',
  usageDurationPlaceholder: '请输入使用时长',
  taskRenewal: '实例续租',
  taskUpgrade: '实例升配',
  resourceSpecificationQuantify: '资源规格数量',
  arithmeticCard: '运算卡',
  total: '总数',
  surplus: '剩余',
  segmentation: '切分',
  dataVersion: '数据集版本',
  permissions: '高级权限',
  on: '开启',
  off: '关闭',
  exclusive: '独享'
}

const addTaskEn = {
  region: 'region',
  resourceSpecifications: 'resource specifications',
  name: 'name',
  node: 'node',
  availableQuantity: 'available quantity',
  price: 'price',
  runningMode: 'running mode',
  standAlone: 'stand alone',
  multiMachine: 'multi machine',
  resourceConfig: 'resource config',
  memory: 'memory',
  systemDisk: 'system',
  networkBandwidth: 'network',
  cacheDisk: 'cache',
  SpecificationQuantity: 'specification quantity',
  platformImage: 'platform image',
  myImage: 'my image',
  submit: 'submit',
  task: 'task',
  change: 'change ',
  billingChanges: 'billing changes',
  chargeType: 'charge type',
  orderDiscount: 'order discount',
  orderPrice: 'order price',
  usageDuration: 'usage duration',
  orderOriginalPrice: 'order original price',
  chargeTypePlaceHolder: 'please select billing type',
  day: 'day',
  month: 'month',
  usageDurationPlaceholder: 'please enter the usage duration',
  taskRenewal: 'task renewal',
  taskUpgrade: 'task upgrade',
  resourceSpecificationQuantify: 'source Specif num',
  arithmeticCard: 'arithmeticCard',
  total: 'total',
  surplus: 'surplus',
  segmentation: 'segmentation',
  dataVersion: 'dataVersion',
  permissions: 'permissions',
  on: 'on',
  off: 'off',
  exclusive: 'exclusive'
}

export {
  addTaskCn,
  addTaskEn
}
