// 主题切换
import { saveStoredColor } from '@/utils/colorStorage'
const changeColor = (color: number): void => {
  saveStoredColor(color)
  if (color === 0) {
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--btnColor', '#3273F9')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--btnHoverColor', '#1757D8')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--btnFocusColor', '#083A9F')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--tableHoverBgColor', '#F4F7FD')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--tableSelectedBgColor', '#E8F0FF')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--tableTheadBgColor', '#EFF4FF')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty(
        '--adminCardHoverColor',
        'linear-gradient(135deg, #009dff 0%, #006dff 100%)'
      )
    document
      .getElementsByTagName('body')[0]
      .style.setProperty(
        '--headNavigationBgColor',
        'linear-gradient(90deg, #3f5efd 0%, #3273f9 100%)'
      )
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--userPayCardBgColor', '#3E44D8')
  }
  if (color === 1) {
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--btnColor', '#F55158')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--btnHoverColor', '#D23037')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--btnFocusColor', '#A5151B')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--tableHoverBgColor', '#FDF4F4')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--tableSelectedBgColor', '#F9E6E6')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--tableTheadBgColor', '#FDF4F4')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty(
        '--adminCardHoverColor',
        'linear-gradient(135deg, #FF7B7F 0%, #F52D90 100%)'
      )
    document
      .getElementsByTagName('body')[0]
      .style.setProperty(
        '--headNavigationBgColor',
        'linear-gradient(90deg, #FF5050 0%, #9D0B93 100%), linear-gradient(90deg, #00C4EA 0%, #10ABEC 100%)'
      )
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--userPayCardBgColor', '#FD5A86')
  }
  if (color === 2) {
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--userPayCardBgColor', '#01ACDC')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--btnColor', '#01BDE3')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--btnHoverColor', '#04A0C0')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--btnFocusColor', '#02758C')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--tableHoverBgColor', '#EEFCFF')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--tableSelectedBgColor', '#D9F4F9')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--tableTheadBgColor', '#EEFCFF')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty(
        '--adminCardHoverColor',
        'linear-gradient(135deg, #08D6FF 0%, #0FBEFF 100%'
      )
    document
      .getElementsByTagName('body')[0]
      .style.setProperty(
        '--headNavigationBgColor',
        'linear-gradient(90deg, #00C4EA 0%, #10ABEC 100%)'
      )
  }
  if (color === 3) {
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--userPayCardBgColor', '#F5B008')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--btnColor', '#E9B025')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--btnHoverColor', '#CD9817')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--btnFocusColor', '#A97B0C')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--tableHoverBgColor', '#FFF8E8')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--tableSelectedBgColor', '#FDF0D4')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--tableTheadBgColor', '#FFF8E8')
    document
      .getElementsByTagName('body')[0]
      .style.setProperty(
        '--adminCardHoverColor',
        'linear-gradient(135deg, #F2BE39 0%, #F3A406 100%)'
      )
    document
      .getElementsByTagName('body')[0]
      .style.setProperty(
        '--headNavigationBgColor',
        'linear-gradient(90deg, #F3BD3A 0%, #DD8538 100%), linear-gradient(90deg, #00C4EA 0%, #10ABEC 100%)'
      )
  }
}
export default changeColor
