import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import Svg from '@/assets/icon/index'
import StButton from '@/components/st-button/index'
import Antd from 'ant-design-vue'
import './permission'
import 'ant-design-vue/dist/reset.css'
import './styles/font/font.css'
import 'normalize.css/normalize.css'
import './styles/index.scss'
import './styles/common.scss'
import 'vue-simple-uploader/dist/style.css'
import i18n from '@/i18n/i18n'
import uploader from 'vue-simple-uploader'
import directives from '@/directive/index' // 自定义指令导入
import loading from './directive/loading'
createApp(App).use(createPinia()).use(StButton).use(uploader).use(Antd).use(Svg).use(loading).use(router).use(i18n).use(directives).mount('#app')
