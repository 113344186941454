const loginZh = {
  scanCode: '扫码登录',
  accountLogin: '账号登录',
  oldPassword: '初始密码',
  newPassword: '新密码',
  checkedPassword: '确认密码',
  loginWord: '登录',
  account: '手机号/邮箱',
  password: '密码',
  codeNum: '图片验证码',
  subUser: '子用户',
  register: '注册',
  findPassword: '忘记密码',
  oldPasswordEdit: '初始密码修改',
  subUserLogin: '子用户登录',
  subUserAccount: '账号@域名',
  backMainUserPage: '返回主用户登录',
  accountRegister: '账号注册',
  phone: '手机号',
  phoneVerifyCode: '手机验证码',
  sendCode: '发送验证码',
  agreeProtocolOne: '我已阅读并同意',
  service: '服务条款',
  privacy: '隐私政策',
  emailRegister: '邮箱注册',
  existingAccount: '已有账号',
  confirm: '确定',
  backLogin: '返回登录',
  ownerLogin: '机主登录',
  adminLogin: '管理员登录',
  userName: '用户名',
  setUserInformation: '用户完善信息',

  emailPlaceholder: '邮箱',
  emailCode: '邮箱验证码',
  phoneRegister: '手机号注册',
  messageRegister: '短信登录',
  scanTips: '请使用微信扫码登录',
  userLogin: '普通用户登录',
  rules: {
    account: '请输入手机号/邮箱',
    password: '请输入密码',
    oldPassword: '请输入初始密码',
    newPassword: '请输入新密码',
    checkedPassword: '请再次确认密码',
    checkedNewPassword: '密码两次输入不一致，请重新输入',
    phone: '请输入手机号',
    phoneRule: '手机号码格式不正确',
    phoneRegister: '手机号已注册，请更换其它手机号',
    code: '请输入验证码',
    name: '请输入用户名',
    imgCode: '请输入图片验证码',
    email: '请输入邮箱',
    emailRule: '邮箱格式不正确',
    emailRegister: '邮箱已注册，请更换其它邮箱'
  },
  isSent: '验证码已发送',
  plant: '思腾合力AI开放平台',
  cookieTips: '我们使用cookie来改善我们的网址和您的体验，继续浏览我们的网址即表示您接受我们的cookie政策。',
  more: '了解更多'
}

const loginEN = {
  scanCode: 'Scan Code Login',
  accountLogin: 'Account login',
  oldPassword: 'Initial password',
  newPassword: 'New password',
  checkedPassword: 'Confirm password',
  loginWord: 'Login',
  account: 'Phone number/email',
  password: 'Password',
  codeNum: 'Image verification code',
  subUser: 'subuser',
  register: 'Register',
  findPassword: 'Forgot Password',
  oldPasswordEdit: 'Initial password change',
  subUserLogin: 'subuser login ',
  subUserAccount: 'account@domain name',
  backMainUserPage: 'Return to main user login',
  accountRegister: 'Account Registration',
  phone: 'mobile number',
  phoneVerifyCode: 'mobile verification code',
  sendCode: 'Send Code',
  agreeProtocolOne: 'I have read and agree',
  service: 'Terms of Service',
  privacy: 'Privacy Policy',
  emailRegister: 'Email Registration',
  existingAccount: 'Existing account',
  confirm: 'confirm',
  backLogin: 'Return to login',
  ownerLogin: 'OwnerLogin',
  adminLogin: 'Administrator login ',
  userName: ' UserName',
  setUserInformation: 'SetUserInformation',
  emailPlaceholder: 'Mailbox ',
  emailCode: ' Email verification code ',
  phoneRegister: 'Phone Registration ',
  messageRegister: 'Message login',
  scanTips: 'Please use wechat scan code to log in',
  userLogin: 'user login',
  rules: {
    account: ' Please enter your phone number/email address ',
    password: ' Please enter password ',
    oldPassword: ' Please enter initial password ',
    newPassword: ' Please enter a new password ',
    checkedPassword: ' Please confirm password again ',
    checkedNewPassword: ' Password entered twice is inconsistent, please re-enter ',
    phone: ' Please enter the phone number ',
    phoneRule: ' Mobile number format is incorrect ',
    phoneRegister: 'The phone number has been registered, please change another phone number',
    code: ' Please enter the verification code ',
    name: ' Please enter user name ',
    imgCode: ' Please enter the image verification code ',
    email: ' Please enter email ',
    emailRule: ' Email format is incorrect ',
    emailRegister: ' Email has been registered, please change to another email '
  },
  isSent: 'code is sent',
  plant: 'Sitonholy AI Open platform ',
  cookieTips: 'We use cookies to improve our website and your experience, by continuing to browse our website you accept our cookie policy. ',
  more: 'Learn more'
}

export { loginZh, loginEN }
