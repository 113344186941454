const addZh = {
  add: '添加',
  edit: '修改',
  success: '成功'
}
const addEn = {
  add: 'add',
  edit: 'edit',
  success: 'success'
}

export {
  addZh,
  addEn
}
