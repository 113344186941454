
import { defineComponent, computed } from 'vue'
export default defineComponent({
  props: {
    iconClass: {
      type: String,
      required: true
    },
    iconText: {
      type: String
    },
    iconStyle: {
      type: Object
    },
    iconPosition: {
      type: Object,
      default: () => {
        return {
          xPosition: '0',
          yPosition: '0'
        }
      }
    }
  },
  setup (props) {
    const iconName = computed<string>(() => {
      return `#icon-${props.iconClass}`
    })
    return {
      iconName,
      props
    }
  }
})
