import router from './router'
import { getToken } from './utils/token'
import { permissionStore } from '@/store/permission'
import { useColorStore } from '@/store/colorPicker'
const whiteList: string[] = [
  '/login',
  '/register',
  '/find-password',
  '/sub-user-login',
  '/portal'
]

router.beforeEach((to, _, next) => {
  const colorStore = useColorStore()
  const permission = permissionStore()
  if (getToken()) {
    if (to.path === '/login') {
      router.go(-1)
    } else {
      if (permission.$state.menuList.length === 0) {
        permission.setMenu().then(res => {
          res.forEach((e: any) => {
            router.addRoute(e)
          })
          next({
            ...to,
            replace: true
          })
        })
      } else {
        next()
      }
      colorStore.$state.visible = true
    }
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next({
        path: '/login',
        replace: true
      })
    }
    colorStore.$state.visible = false
  }
})
