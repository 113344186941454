const workOrderZh = {
  number: '工单编号',
  name: '工单名称',
  desc: '问题描述',
  level: '问题等级',
  type: '工单类型',
  sub: '子用户',
  status: '状态',
  orderTime: '下单时间',
  unread: '未读信息',
  search: '搜索',
  task: '实例',
  storage: '存储',
  image: '镜像',
  charging: '计费',
  user: '用户',
  add: '添加',
  edit: '修改',
  revoke: '撤回',
  examine: '审批',
  normal: '一般',
  danger: '紧急',
  detail: '详情说明',
  fileUpload: '文件上传',
  upload: '上传',
  nameInput: '请输入工单名称',
  typeInput: '请选择工单类型',
  levelInput: '请选择工单等级',
  detailInput: '请输入详细说明',
  uploadInput: '请上传相关文件',
  phone: '手机号',
  account: '创建用户',
  submit: '已提交',
  revoked: '已撤销',
  over: '已结束',
  recall: '撤回',
  reason: '原因',
  reasonInput: '请输入撤回原因',
  orderInfo: '工单信息',
  productName: '产品名称',
  problemType: '问题类型',
  createTime: '创建时间',
  chat: '沟通会话',
  annex: '附件',
  send: '发送',
  node: '节点',
  config: '配置',
  order: '订单',
  monitor: '监控',
  product: '产品',
  other: '其他',
  approval: '请输入审批原因',
  pleaseEnter: '请输入',
  pleaseSelect: '请选择',
  operate: '操作',
  recallSuccess: '撤回成功'
}

const workOrderEn = {
  number: 'number',
  name: 'name',
  desc: 'describe',
  level: 'level',
  type: 'type',
  sub: 'subUser',
  status: 'status',
  orderTime: 'order time',
  unread: 'unread msg',
  search: 'search',
  task: 'task',
  storage: 'storage',
  image: 'image',
  charging: 'charging',
  user: 'user',
  add: 'add',
  edit: 'edit',
  revoke: 'revoke',
  examine: 'examine',
  normal: 'normal',
  danger: 'danger',
  detail: 'detail',
  fileUpload: 'file upload',
  upload: 'upload',
  nameInput: 'please enter the job name',
  typeInput: 'please select a work order type',
  levelInput: 'please select a work order level',
  detailInput: 'please enter a detailed description',
  uploadInput: 'please upload relevant files',
  phone: 'phone',
  account: 'account',
  submit: 'submit',
  revoked: 'revoke',
  over: 'over',
  recall: 'recall ',
  reason: 'reason',
  reasonInput: 'please enter the reason for withdrawal',
  orderInfo: 'order info',
  productName: 'product name',
  problemType: 'problem type',
  createTime: 'create time',
  chat: 'chat',
  annex: 'annex',
  send: 'send',
  node: 'node',
  config: 'config',
  order: 'order',
  monitor: 'monitor',
  product: 'product',
  other: 'other',
  approval: 'please enter the approval reason',
  pleaseEnter: 'Please enter ',
  pleaseSelect: 'Please select ',
  operate: 'operate',
  recallSuccess: 'revoke success'
}

export {
  workOrderZh,
  workOrderEn
}
