<template>
  <div id="global-uploader" :class="{ 'global-uploader-single': !global }">
    <!-- 上传 -->
    <uploader
      ref="uploaderRef"
      class="uploader-app"
      :options="initOptions"
      :file-status-text="fileStatusText"
      :auto-start="false"
      @file-added="onFileAdded"
      @file-success="onFileSuccess"
      @file-error="onFileError"
    >
      <uploader-unsupport></uploader-unsupport>

      <uploader-btn id="global-uploader-btn" ref="uploadBtnRef">选择文件</uploader-btn>

      <uploader-list v-show="panelShow">
        <template #default="{ fileList }">
          <div class="file-panel" :class="{ collapse: collapse }">
            <div class="file-title">
              <div class="title">文件列表</div>
              <div class="operate">
                <a-button
                  :title="collapse ? '展开' : '折叠'"
                  link
                  @click="collapse = !collapse"
                >
                  <!-- <Icon
                    :icon="collapse ? 'ri:fullscreen-fill' : 'ri:subtract-fill'"
                    width="18"
                  /> -->
                  <template #icon>
                    <ExpandOutlined v-if="collapse" />
                    <MinusOutlined v-if="!collapse" />
                  </template>
                </a-button>
                <a-button title="关闭" link @click="handleCloseUpload(fileList)">
                  <template #icon>
                    <CloseOutlined />
                  </template>
                  <!-- <Icon icon="ri:close-fill" width="20" /> -->
                </a-button>
              </div>
            </div>

            <ul class="file-list">
              <li v-for="file in fileList" :key="file.id" class="file-item">
                <uploader-file
                  ref="files"
                  :class="['file_' + file.id, customStatus]"
                  :file="file"
                  :list="true"
                ></uploader-file>
              </li>
              <div v-if="!fileList.length" class="no-file">
                <!-- <Icon icon="ri:file-3-line" width="16" />  -->
                暂无待上传文件
              </div>
            </ul>
          </div>
        </template>
      </uploader-list>
    </uploader>
  </div>
</template>

<script>
import { ref, watch, computed, nextTick, onMounted } from 'vue'
import { generateMD5 } from './utils/md5'
import Bus from './utils/bus'
import http from '@/utils/http'
import { message, Modal } from 'ant-design-vue'
import { useRoute } from 'vue-router'
import { CloseOutlined, ExpandOutlined, MinusOutlined } from '@ant-design/icons-vue'

export default {
  name: 'GlobalUploader',
  components: {
    CloseOutlined,
    ExpandOutlined,
    MinusOutlined
  },
  props: {
    global: {
      type: Boolean,
      default: true
    },
    // 发送给服务器的额外参数
    params: {
      type: Object
    },
    options: {
      type: Object
    }
  },
  emits: ['fileAdded', 'fileSuccess'],

  setup (props, { emit }) {
    const route = useRoute()
    const messageTips = ref()
    const objectMd5 = ref()
    const maxFileList = ref([])
    const fileType = ref()
    const shardUrl = ref()
    const queryFullPathObj = ref()
    const initOptions = {
      // header:props.options.headers,
      // headers: { [localStorage.getItem("headerKey")]: getToken() }, // 接口必须
      // target: "https://192.168.207.40/api/storage/nfsOperation/uploadSlicingFile",
      chunkSize: 20 * 1024 * 1024,
      fileParameterName: 'file',
      maxChunkRetries: 1,
      // 是否开启服务器分片校验
      testChunks: false,
      singleFile: false,
      simultaneousUploads: 1,
      // 服务器分片校验函数，秒传及断点续传基础
      // checkChunkUploadedByResponse: function (chunk, message, file) {
      //   let skip = false;
      //   console.log("message", message);
      //   messageTips.value = JSON.parse(message);
      //   try {
      //     let objMessage = JSON.parse(message);
      //     if (objMessage.skipUpload) {
      //       skip = true;
      //     } else {
      //       skip = (objMessage.uploaded || []).indexOf(chunk.offset + 1) >= 0;
      //     }
      //   } catch (e) {}
      //   return skip;
      // },
      parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
        // 格式化时间
        return parsedTimeRemaining
          .replace(/\syears?/, '年')
          .replace(/\days?/, '天')
          .replace(/\shours?/, '小时')
          .replace(/\sminutes?/, '分钟')
          .replace(/\sseconds?/, '秒')
      },
      query: (file, chunk) => {
        // console.log(parseInt(file.chunks.length));
        // console.log(chunk);
        // console.log(file);
        // var xhr = new XMLHttpRequest(chunk.xhr);
        // console.log(xhr);
        // if (xhr.readyState != 4) return;
        // if (xhr.status == 200) {
        //   file.params.shardIndex = parseInt(chunk.offset + 1);
        //   file.params.shardSize = chunk.chunkSize;
        //   file.params.size = file.size;
        //   file.params.suffix = file.fileType;
        //   file.params.shardTotal = parseInt(file.chunks.length);
        //   if (fileType.value !== "0") {
        //     file.params.shardUrl = chunk.shardUrl;
        //     file.params.uploadId = file.uploadId;
        //   }
        //   resolve(xhr.responseText);
        //   return {
        //     ...file.params,
        //   };
        // } else {
        //     // reject("服务器错误");
        //     file.cancel();
        // }
        if (!messageTips.value || messageTips.value.code === '0') {
          file.params.shardIndex = parseInt(chunk.offset + 1)
          file.params.shardSize = chunk.chunkSize
          file.params.size = file.size
          file.params.suffix = file.fileType
          file.params.shardTotal = parseInt(file.chunks.length)
          if (fileType.value !== '0') {
            file.params.shardUrl = chunk.shardUrl
            file.params.uploadId = file.uploadId
          }
          // console.log("params", file.params);
          return {
            ...file.params
          }
        } else {
          // if (messageTips.value.code !== "0") {
          error(messageTips.value.msg)
          file.cancel()
          // }
          // statusRemove(file.id);
        }
      },
      processParams: (file) => {
        // console.log(file);
        // console.log(
        //   "file",
        //   file.identifier + ":" + file.chunkNumber + "。分片总数：" + file.shardTotal
        // );
        var params = {}
        if (fileType.value !== '2' && fileType.value !== '4') {
          params = {
            bucketId: file.bucketId,
            fileName: queryFullPathObj.value + file.filename,
            shardIndex: file.shardIndex,
            shardTotal: file.shardTotal,
            shardUrl: file.shardUrl,
            uploadId: file.uploadId,
            size: file.size
          }
        } else {
          params = {
            bucketId: file.bucketId,
            fileName: file.filename,
            fullPath: file.fullPath,
            key: file.identifier,
            shardSize: file.shardSize,
            shardIndex: file.shardIndex,
            shardTotal: file.shardTotal,
            size: file.size,
            suffix: file.suffix
          }
        }
        return params
      }
    }
    const fileStatusText = {
      success: '上传成功',
      error: '上传失败',
      uploading: '上传中',
      paused: '已暂停',
      waiting: '等待上传'
    }
    const customStatus = ref('')
    const panelShow = ref(false)
    const collapse = ref(false)
    const uploaderRef = ref()
    const uploadBtnRef = ref()
    // let mergeFn = mergeSimpleUpload;

    const uploader = computed(() => uploaderRef.value?.uploader)

    let customParams = {}
    watch(
      () => props.params,
      (data) => {
        if (data) {
          customParams = data
        }
      }
    )
    watch(
      () => props.options,
      (data) => {
        if (data) {
          setTimeout(() => {
            customizeOptions(data)
          }, 0)
        }
      }
    )
    // 自定义options
    function customizeOptions (opts) {
      if (opts) {
        // 自定义上传url
        if (opts.target) {
          uploader.value.opts.target = opts.target
        }
        if (opts.headers) {
          uploader.value.opts.headers = opts.headers
        }
        // 是否可以秒传、断点续传
        if (opts.testChunks !== undefined) {
          uploader.value.opts.testChunks = opts.testChunks
        }

        // merge 的方法，类型为Function，返回Promise
        // if (opts.mergeFn) {
        //   mergeFn = opts.mergeFn;
        // }
        // 自定义文件上传类型
        if (opts.accept) {
          const input = document.querySelector('#global-uploader-btn input')
          input.setAttribute(opts.accept, opts.accept.join())
        }
      }
    }
    async function onFileAdded (file) {
      panelShow.value = true
      sessionStorage.setItem('storageType', route.query.type)
      sessionStorage.setItem('bucketId', route.query.id)
      maxFileList.value = []
      trigger('fileAdded')
      // 将额外的参数赋值到每个文件上，以不同文件使用不同params的需求
      file.params = customParams
      fileType.value = file.params.type
      queryFullPathObj.value = file.params.queryFullPathObj
      if (sessionStorage.getItem('storageType') === '1') {
        await getStorageFiles(file)
        // let res = await computeMD5(file, shardData.uploadId, shardData.vos);
        // console.log("res", res);
        // console.log(MD5);
        // startUpload(res, shardData.uploadId);
      } else {
        // console.log("share");
        computeMD5(file)
        // console.log(res);
        // console.log(res.file);
        // console.log(res.md5);
        // startUpload(file,res.md5);
      }
      // 计算MD5
      // const md5 =
      // startUpload(file, md5);
    }
    function computeMD5 (file, uploadId) {
      // 文件状态设为"计算MD5"
      statusSet(file.id, 'md5')
      // 暂停文件
      file.pause()
      // 计算MD5时隐藏”开始“按钮
      // nextTick(() => {
      //   document.querySelector(`.file-${file.id} .uploader-file-resume`).style.display =
      //     "none";
      //   document.querySelector(`.file-${file.id} .uploader-file-remove`).style.display =
      //     "none";
      // });
      // 开始计算MD5
      return new Promise((resolve, reject) => {
        // if (uploadId) {
        //   file.params.shardIndex = currentChunk.value;
        //   file.params.shardTotal = chunks.value;
        //   shardTotal.value = shardTotal.value;
        //   statusRemove(file.id);
        //   resolve(file);
        //   // startUpload(file, uploadId);
        // } else {
        generateMD5(file, {
          onProgress (currentChunk, chunks) {
            // if (fileType.value !== "0") {
            //   file.params.shardUrl = vos[currentChunk - 1].chunkUrl;
            // }
            // 实时展示MD5的计算进度
            nextTick(() => {
              const md5ProgressText =
              '校验MD5 ' + ((currentChunk / chunks) * 100).toFixed(0) + '%'
              document.querySelector(
                `.custom-status-${file.id}`
              ).innerText = md5ProgressText
            })
          },
          onSuccess (md5) {
            // console.log('chunks.value',chunks)
            // file.params.shardIndex = currentChunk.value;
            // file.params.shardTotal = chunks.value;
            // file.params.fileName = file.name;
            // file.params.size = file.size;
            // shardTotal.value = shardTotal.value;
            // if (uploadId) {
            //   file.params.uploadId = uploadId;
            // } else {
            //   file.params.key = md5;
            // }
            statusRemove(file.id)
            // resolve(file, md5);
            if (uploadId) {
              startUpload(file, uploadId)
            } else {
              startUpload(file, md5)
              // resolve({ file: file, md5: md5 });
            }
            // startUpload(file, md5);
          },
          onError () {
            error(`文件${file.name}读取出错，请检查该文件`)
            file.cancel()
            statusRemove(file.id)
            reject(new Error('err'))
          }
        })
        // }
      })
    }
    // md5计算完毕，开始上传
    function startUpload (file, md5) {
      file.params.size = file.size
      if (fileType.value !== '1') {
        file.uniqueIdentifier = md5
      }
      // console.log(file.params.key + " :" + file.params.shardIndex);
      file.resume()
    }
    function onFileSuccess (rootFile, file, response) {
      const res = JSON.parse(response)
      // 服务端自定义的错误（即http状态码为200，但是是错误的情况），这种错误是Uploader无法拦截的
      if (res.code === '-1') {
        error(res.msg)
        // 文件状态设为“失败”
        statusSet(file.id, 'failed')
        return false
      } else {
        trigger('fileSuccess')
      }

      // // 如果服务端返回了需要合并的参数
      // if (res.needMerge) {
      //   // 文件状态设为“合并中”
      //   statusSet(file.id, "merging");
      //   mergeFn({
      //     tempName: res.tempName,
      //     fileName: file.name,
      //     ...file.params,
      //   })
      //     .then((res) => {
      //       // 文件合并成功
      //       trigger("fileSuccess");
      //       statusRemove(file.id);
      //     })
      //     .catch((e) => {});
      //   // 不需要合并
      // } else {
      //   trigger("fileSuccess");
      // }
    }
    const getStorageFiles = async (file) => {
      const res = await http({
        method: 'post',
        url: '/storage/objOperation/fileChunkUrl',
        data: {
          bucketId: customParams.bucketId,
          fileName: queryFullPathObj.value + file.name,
          shardTotal: file.chunks.length,
          size: file.size
        }
      })
      var list = res.data
      list.fileName = queryFullPathObj.value + file.name
      list.size = file.size
      shardUrl.value = res.data.vos
      objectMd5.value = res.data.uploadId
      file.uploadId = res.data.uploadId
      // file.params.shardUrl = res.data.vos[file.chunk.offset].chunkUrl;
      for (var i = 0; i < file.chunks.length; i++) {
        file.chunks[i].shardUrl = res.data.vos[i].chunkUrl
      }
      maxFileList.value.push(list)
      computeMD5(file, res.data.uploadId, res.data.vos)
      // return res.data;
    }
    // function onFileProgress (_rootFile, _file, _chunk) {
    // console.log(
    //   `上传中 ${file.name}，chunk：${chunk.startByte / 1024 / 1024} ~ ${
    //     chunk.endByte / 1024 / 1024
    //   }`
    // );
    // }
    function onFileError (_rootFile, file, response) {
      const res = JSON.parse(response)
      // 服务端自定义的错误（即http状态码为200，但是是错误的情况），这种错误是Uploader无法拦截的
      if (res.code === '-1') {
        error(res.msg)
        // 文件状态设为“失败”
        statusSet(file.id, 'failed')
        return false
      }
    }
    function close () {
      uploader.value.cancel()
      panelShow.value = false
      uploader.value = null
    }
    function handleCloseUpload (data) {
      var flag = []
      data.map((v) => {
        if (!v.completed) {
          flag.push(false)
        }
      })
      if (flag.length > 0) {
        Modal.confirm({
          title: '系统提示',
          content: '关闭后若有文件正在上传，则会终止操作，是否确认关闭?',
          centered: true,
          onOk () {
            close()
          }
        })
      } else {
        close()
      }
    }

    function statusSet (id, status) {
      const statusMap = {
        md5: {
          text: '校验MD5',
          bgc: '#fff'
        },
        merging: {
          text: '合并中',
          bgc: '#e2eeff'
        },
        transcoding: {
          text: '转码中',
          bgc: '#e2eeff'
        },
        failed: {
          text: '上传失败',
          bgc: '#e2eeff'
        }
      }

      customStatus.value = status
      nextTick(() => {
        const statusTag = document.createElement('p')
        statusTag.className = `custom-status-${id} custom-status`
        statusTag.innerText = statusMap[status].text
        statusTag.style.backgroundColor = statusMap[status].bgc

        const statusWrap = document.querySelector(`.file_${id} .uploader-file-status`)
        statusWrap.appendChild(statusTag)
      })
    }
    function statusRemove (id) {
      customStatus.value = ''
      nextTick(() => {
        const statusTag = document.querySelector(`.custom-status-${id}`)
        statusTag.remove()
      })
    }
    function trigger (e) {
      Bus.emit(e)
      emit(e)
    }
    function error (msg) {
      message.error(msg)
    }
    Bus.on('closeUploader', ({ params }) => {
      if (params.action === 'close') {
        close()
      }
    })
    onMounted(() => {
      Bus.on('openUploader', ({ params, options }) => {
        // uploader.value = null
        // panelShow.value = false;
        if (params.type) {
          fileType.value = params.type
        }
        customParams = params
        if (uploader.value) {
          customizeOptions(options)
          // 监听浏览器刷新
          window.addEventListener('beforeunload', (e) => {
            e.preventDefault()
            e.returnValue = ''
          })
        }
        if (uploadBtnRef.value) {
          uploadBtnRef.value.$el.click()
        }
      })
    })

    return {
      initOptions,
      fileStatusText,
      customStatus,
      panelShow,
      collapse,
      uploaderRef,
      uploadBtnRef,
      onFileAdded,
      onFileSuccess,
      onFileError,
      close,
      handleCloseUpload
    }
  }
}
</script>

<style lang="scss">
#global-uploader {
  &:not(.global-uploader-single) {
    position: fixed;
    z-index: 20;
    right: 15px;
    bottom: 15px;
    box-sizing: border-box;
  }

  .uploader-app {
    width: 520px;
  }

  .file-panel {
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 7px 7px 0 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    .file-title {
      display: flex;
      height: 40px;
      line-height: 40px;
      padding: 0 15px;
      border-bottom: 1px solid #ddd;

      .operate {
        flex: 1;
        text-align: right;

        .ant-btn {
          margin-left: 8px;
        }
      }
    }

    .file-list {
      position: relative;
      height: 240px;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: #fff;
      transition: all 0.3s;

      .file-item {
        background-color: #fff;
      }
    }

    &.collapse {
      .file-title {
        background-color: #e7ecf2;
      }
      .file-list {
        height: 0;
      }
    }
  }

  .no-file {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #999;

    svg {
      vertical-align: text-bottom;
    }
  }

  .uploader-file {
    &.md5 {
      .uploader-file-resume {
        display: none;
      }
      .uploader-file-remove {
        display: none;
      }
    }
  }

  .uploader-file-icon {
    &:before {
      content: "" !important;
    }

    &[icon="image"] {
      background: url(./images/image-icon.png);
    }
    &[icon="audio"] {
      background: url(./images/audio-icon.png);
      background-size: contain;
    }
    &[icon="video"] {
      background: url(./images/video-icon.png);
    }
    &[icon="document"] {
      background: url(./images/text-icon.png);
    }
    &[icon="unknown"] {
      background: url(./images/zip.png) no-repeat center;
      background-size: contain;
    }
  }

  .uploader-file-actions > span {
    margin-right: 6px;
  }
  .uploader-file-actions{
    width: unset;
    float: right;
  }
  .uploader-file-status{
    min-width:100px;
    width: unset;
    text-indent:unset;
  }
  .uploader-file-size{
    width: unset;
  }
.uploader-file-name{
  width:30%
}
  .custom-status {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}

/* 隐藏上传按钮 */
#global-uploader-btn {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.global-uploader-single {
  #global-uploader-btn {
    position: relative;
  }
}
</style>
