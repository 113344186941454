const getStoredColor = (): any => {
  return localStorage.getItem('themeColor')
}

const saveStoredColor = (color: number | string): void => {
  localStorage.setItem('themeColor', color as any)
}

export {
  getStoredColor,
  saveStoredColor
}
