import { defineStore } from 'pinia'
import { queryMenuApi } from '@/api/nav'
// import menusInfo from '@/mock/menu.json'
interface AccountInfoFace {
  account: string
  userId: string
  userType: number
  portrait: string
  gradeName: string,
  isMaster:boolean
}
interface PermissionFace {
  accountInfo: AccountInfoFace,
  menuList: any[],
  filterMenuList: any[]
}

const permissionStore = defineStore('permission', {
  state: ():PermissionFace => ({
    accountInfo: {
      account: '',
      userId: '',
      userType: 0,
      portrait: '',
      gradeName: '',
      isMaster: true
    },
    menuList: [],
    filterMenuList: []
  }),
  actions: {
    setMenu (): Promise<any> {
      return new Promise((resolve, reject) => {
        queryMenuApi().then(res => {
          // const routes = resloveComponents(menusInfo.menus)
          const routes = resloveComponents(res.data.menus)
          this.accountInfo.account = res.data.account
          this.accountInfo.userId = res.data.id
          this.accountInfo.userType = res.data.userType
          this.accountInfo.portrait = res.data.portrait
          this.accountInfo.gradeName = res.data.gradeName
          this.accountInfo.isMaster = res.data.isMaster
          // this.menuList = menusInfo.menus
          this.menuList = res.data.menus
          resolve(routes)
        }).catch(err => {
          reject(err)
        })
      })
    },
    filterMenus (target: string) {
      this.menuList.forEach(item => {
        if (item.meta.menuTitle.includes(target)) {
          this.filterMenuList.push(item)
        } else {
          item.children.forEach((citem: any) => {
            if (citem.meta.menuTitle.includes(target)) {
              this.filterMenuList.push(item)
            }
          })
        }
      })
    }
  }
})

function resloveComponents (params: any[]): any[] {
  params.forEach(item => {
    item.component = loadView(item.component)
    if (item.children) {
      resloveComponents(item.children)
    }
  })
  return params
}
function loadView<T> (view: T): any {
  return () => require.ensure([], (require) => require(`@/${view}.vue`))
}

export {
  permissionStore
}
