const stationMessageZh = {
  del: '删除',
  read: '标为已读',
  allRead: '全部标为已读',
  title: '标题',
  message: '消息',
  createTime: '创建时间',
  oneClickRead: '一键已读',
  notificationMessage: '通知消息'
}
const stationMessageEn = {
  del: 'del',
  read: 'mark read',
  allRead: 'mark all red',
  title: 'title',
  message: 'message',
  createTime: 'createTime',
  oneClickRead: 'one click read',
  notificationMessage: 'notification message'
}

export {
  stationMessageZh,
  stationMessageEn
}
