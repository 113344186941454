import { maintenanceCenterZh } from './module/maintenance-center'
import { costCenterZh } from './module/cost-center'
import { addTaskCn } from './module/add-task'
import { myTaskZh } from './module/mytask'
import { netDiskZh } from './module/net-disk'
import { imageZh } from './module/image'
import { delZh } from './module/del'
import { userCenterZh } from './module/user-center'
import { stationMessageZh } from './module/station-message'
import { workOrderZh } from './module/work-order'
import { addZh } from './module/add'
import { subUserManagementZh } from './module/sub-user-management'
import { headNavigationZh } from './module/head-navigation'
import { loginZh } from './module/login'
export default {
  maintenanceCenter: maintenanceCenterZh,
  costCenter: costCenterZh,
  addTask: addTaskCn,
  myTask: myTaskZh,
  netDisk: netDiskZh,
  image: imageZh,
  del: delZh,
  userCenter: userCenterZh,
  stationMessage: stationMessageZh,
  workOrder: workOrderZh,
  add: addZh,
  subUserManagement: subUserManagementZh,
  headNavigation: headNavigationZh,
  login: loginZh
}
