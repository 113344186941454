import http from '@/utils/http'
interface Response {
  [propName: string]: any;
}

// 查询路由菜单
const queryMenuApi = (): Promise<Response> => {
  return http({
    method: 'get',
    url: '/user/user/queryUserRole'
  })
}

// 查询最近访问
const queryRecentlyVisitedMenusApi = (): Promise<Response> => {
  return http({
    method: 'get',
    url: '/user/user/queryUserMenuLog'
  })
}

// 查询收藏菜单
const queryCollectionMenusApi = (): Promise<Response> => {
  return http({
    method: 'get',
    url: '/user/user/queryUserCollectMenu'
  })
}

// 删除收藏菜单
const delCollectionMenuApi = (data: string) => {
  return http({
    method: 'post',
    url: `/user/user/removeMenu/${data}`
  })
}

// 菜单收藏
const collectionMenuApi = (data: string) => {
  return http({
    method: 'post',
    url: `/user/user/collectMenu/${data}`
  })
}

export {
  queryMenuApi,
  queryRecentlyVisitedMenusApi,
  queryCollectionMenusApi,
  delCollectionMenuApi,
  collectionMenuApi
}
