
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  emits: ['click'],
  props: {
    type: {
      type: String,
      default: 'default'
    },
    htmlType: {
      type: String as PropType<any>,
      default: 'button'
    },
    ghost: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const btnClassName = computed<string>(() => {
      return `st-btn-${props.type}`
    })

    const ghostClassName = computed<string>(() => {
      if (props.ghost) {
        return 'st-btn-background-ghost'
      } else {
        return ''
      }
    })

    const handleClick = () => {
      emit('click')
    }

    return {
      btnClassName,
      handleClick,
      ghostClassName
    }
  }
})
