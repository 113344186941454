import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-030985d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "process-num" }
const _hoisted_2 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_progress = _resolveComponent("a-progress")!

  return (_ctx.download.$state.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "download-square",
        onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMouseDown && _ctx.handleMouseDown(...args))),
        onMouseup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMouseUp && _ctx.handleMouseUp(...args))),
        style: _normalizeStyle(_ctx.movePositionClassName),
        ref: "domRef"
      }, [
        _createVNode(_component_a_progress, {
          class: "progress",
          type: "circle",
          "stroke-color": {
        '0%': '#fff',
        '100%': '#fff',
      },
          "stroke-width": 9,
          percent: _ctx.download.$state.progressPercent,
          size: 55
        }, {
          format: _withCtx((percent) => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(percent) + "%", 1)
          ]),
          _: 1
        }, 8, ["percent"]),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.progressText), 1)
      ], 36))
    : _createCommentVNode("", true)
}