import JSEncrypt from 'jsencrypt'

const publicKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAnNwqZaCc\n
qdH4dmNlR6vP8q4QfmNchVoJfczlOfGnRNoF7+QDHSxvQru7ZLFsj8\n
7zvteiLkCs6oFAGv1AKL0trpTw7Kpuas26qHOuSEm4phlyP2nYaXWm\n
gHZcQW7MaAxI45ZYuIp0MVpas8ZjOi/oGbx1H1bqcYCk3rWF2rKcw\n
O3ugNFuEu/1h68omkobHEX2p3wIkVndiVv4AUSjjOkEIBxj4qdAgolIn\n
hk5XQlZlc8Tx58prR1mYPNzC26Vzf+keesm2JKpt1JtuwiXok5jF2Dpm\n
nBlvgh36qnwF4jJ0t5i7Lm90uKu2AU8gsIMUdP3PH7mXtdmFcx++x4\n
1XGe8yQIDAQAB`

const encrtpt = (txt:string): string | boolean => {
  const encrtpt = new JSEncrypt()
  encrtpt.setPublicKey(publicKey)
  return encrtpt.encrypt(txt)
}

export {
  encrtpt
}
