const maintenanceCenterZh = {
  addmessage: '添加成功',
  editmessage: '修改成功',
  operation: '操作',
  // region
  region: '区域',
  inputPlaceholder: '请输入',
  selectPlaceholder: '请选择',
  createTime: '创建时间',
  startPlaceholder: '开始时间',
  endPlaceholder: '结束时间',
  search: '搜索',
  add: '添加',
  edit: '编辑',
  del: '删除',
  isGateway: '区域网关',
  ipAddress: 'ip地址',
  port: '端口',
  authKey: '认证key',
  yes: '是',
  no: '否',
  // calculate
  model: '型号',
  updateTime: '更新时间',
  videoStorage: '显存',
  floatingPointComputingPower: '浮点算力',
  cudaMaxVersion: 'Toolkit版本',

  // agent-device
  agentIp: '内网代理地址',
  agentStartPort: '起始端口',
  agentEndPort: '结束端口',
  serverPort: '服务端口',
  authenticationKey: '认证token',
  flag: '标识符',
  oldFlag: '旧代理设备',
  newFlag: '新代理设备',
  publicIp: '外网代理地址',
  // ai-device
  hoseAlias: '节点别名',
  ip: 'IP地址',
  deviceStatus: '设备状态',
  online: '在线',
  offline: '离线',
  gpuManageId: '运算卡型号',
  createBy: '创建人',
  image: '节点镜像',
  bmc: 'BMC配置',
  reset: '重启',
  shutdown: '关机',
  synchronization: '同步',
  gpusetting: '运算卡配置',
  monitor: '节点监控',
  listing: '上架',
  delist: '下架',
  upgrades: '升级',
  agentDeviceId: '代理设备IP',
  description:
    '提示：设备部署时。注意复制认证密码到训练设备的节点服务上，启动成功后，系统会自动将状态改为在线。',
  currentState: '当前状态',
  exclusive: '独享',
  reuse: '复用',
  mode: '切换模式',
  migNum: '单卡复用/MIG数量',
  imageDetail: '节点镜像详情',
  imageName: '镜像名称',
  status: '状态',
  inuse: '使用中',
  unused: '未使用',
  delAllNone: '删除所有none镜像',
  delAllUnused: '删除所有未使用镜像',
  bmcAddress: 'BMC地址',
  bmcAccount: 'BMC账号',
  bmcPassword: 'BMC密码',
  back: '返回',
  basic: '基本信息',
  accordingTime: '按时按量',
  accordingYear: '包年包月',
  cpuMsg: 'CPU信息',
  memoryMsg: '内存/硬盘信息',
  gpuMsg: '运算卡信息',
  regionTips: '暂无数据。请先选择区域，再选择代理设备IP',
  operateSystem: '操作系统',
  segmentation: '切分',
  singleGpuSegAmount: '单卡切分数量',
  // storage-device
  storageType: '类型',
  storageIp: 'IP地址',
  storageStatus: '存储状态',
  quotaStatus: '配额状态',
  enable: '启用',
  close: '关闭',
  storageName: '名称',
  route: '路径',
  calculateDeviceIp: '计算设备IP',
  nodeDeviceIp: '计算节点IP',

  // ResourceSpecification
  specificationName: '名称',
  resource: '可用资源',
  laveMemory: '内存',
  laveHardDisk: '硬盘',
  laveCacheDisk: '缓存盘',
  gpuNum: '运算卡',
  cacheDiskMaxLimit: '缓存盘',
  specificationNum: '数量',
  singleVideoStorage: '单卡显存',
  // areaLimit
  type: '类型',
  userGroup: '用户组',
  user: '用户',
  resourceSpecification: '资源规格',
  cancel: '取 消',
  confirm: '确 定',
  // license
  offlineLicense: '离线授权',
  onlineLicense: '在线授权',
  copy: '复制',
  officialVersion: '正式版',
  betaVersion: '测试版',
  open: '开启',
  shut: '关闭',
  uploadCertificate: '上传证书',
  uploadTipsOne: '将文件拖到此处或点击上传',
  uploadTipsTwo: '单次仅支持单个文件上传',

  maker: '厂商',

  systemWarning: '系统提示',

  // TaskSystemSetting
  TASK_RELEASE_TIME: '实例释放时间(小时)',
  CACHE_RELEASE_TIME: '缓存盘释放时间(小时)',
  taskFlag: '实例配置',
  useServer: '使用服务标识',
  realNameAuth: '实名认证',

  // certificate
  lapseFlag: '过期标识符',
  expired: '已过期',
  noexpired: '未过期',
  lapseTime: '失效时间',
  normal: '启用',
  certFile: '证书文件',
  keyFile: '密钥文件',

  // ssh
  remark: '备注',
  alias: '别名',
  publicKey: '公钥',

  rules: {
    region: '请输入区域名称',
    model: '请输入型号',
    videoStorage: '请输入显存',
    floatingPointComputingPower: '请输入浮点算力',
    cudaMaxVersion: '请输入最高支持Toolkit版本',
    makerId: '请输入厂商',
    regionId: '请选择区域',
    agentIp: '请输入代理地址',
    correctIp: '请输入正确的代理地址',
    agentStartPort: '请输入起始端口',
    agentEndPort: '请输入结束端口',
    serverPort: '请输入服务端口',
    authenticationKey: '请输入认证token',
    flag: '请选择标识符',
    mode: '请选择模式',
    migNum: '请输入MIG数量',
    migLimit: '独享模式下，mig数量只可输入1',
    hoseAlias: '请输入节点别名',
    ip: '请输入IP地址',
    ipRule: 'IP地址错误',
    gpuManageId: '请选择运算卡型号',
    agentDeviceId: '请先选择区域，再选择代理设备IP',
    bmcAccount: '请输入BMC账户',
    bmcPassword: '请输入BMC密码',

    storageName: '请输入存储名称',
    storageIp: '请输入存储IP',
    type: '请选择存储类型',
    route: '请输入存储路径',
    calculateDeviceId: '请选择计算设备',

    specificationName: '请输入资源规格名称',
    status: '请选择状态',
    cpuCoreNum: '请输入CPU',
    cpuCoreNumLimit: 'CPU数量超过剩余资源，请重新输入',
    gpuNum: '请输入运算卡',
    gpuNumLimit: '运算卡数量超过剩余资源，请重新输入',
    input: '请输入',
    hardDiskLimit: '硬盘超过剩余资源，请重新输入',
    memory: '请输入内存',
    memoryLimit: '内存超过剩余资源，请重新输入',
    cacheDiskMax: '请输入缓存盘',
    cacheDiskMaxLimit: '缓存盘超过剩余资源，请重新输入',
    specificationNum: '请输入规格数量',
    overCpuCoreNum: 'CPU数量已超出资源份额剩余量，请重新输入',
    overGpuNum: '运算卡数量已超出资源份额剩余量，请重新输入',
    overMemory: '内存已超出资源份额剩余量，请重新输入',
    overHardDisk: '硬盘已超出资源份额剩余量，请重新输入',
    overCacheDiskMaxLimit: '缓存盘已超出资源份额剩余量，请重新输入',

    userId: '请选择用户',
    poolId: '请选择资源池',

    isGateway: '请输入区域网关',
    port: '请输入端口',
    ipAddress: '请输入IP',
    authKey: '请输入认证key',

    delFlag: '与要求输入标识不匹配，请重新输入',
    delGpu: '即将删除以下运算卡',
    delTips: '如果您确定要删除，请输入',
    delDevice: '即将删除以下设备',
    operateSystem: '请选择操作系统',
    useServer: '请选择使用服务标识',
    toolkitVersion: '请输入Toolkit版本',
    quotaStatus: '请选择配额状态',

    singleGpuSegAmount: '请输入切分数量',

    alias: '请输入别名',
    publicKey: '请输入公钥'
  }
}
const maintenanceCenterEn = {
  addmessage: 'Add success ',
  editmessage: 'Modified successfully ',
  operation: 'operation',
  // region
  region: 'RegionName',
  inputPlaceholder: 'Please enter',
  selectPlaceholder: 'Please select',
  createTime: 'Create time',
  startPlaceholder: 'Start time',
  endPlaceholder: 'end time',
  search: 'Search',
  add: 'add',
  edit: 'Edit',
  del: 'Delete',
  isGateway: 'isGateway',
  ipAddress: 'ipAddress',
  port: 'port',
  authKey: 'authKey',
  yes: 'yes',
  no: 'no',
  // calculate
  model: 'model',
  updateTime: 'Update time',
  videoStorage: 'Video memory',
  floatingPointComputingPower: 'floatingPointComputingPower',
  cudaMaxVersion: 'ToolkitMaxVersion',
  // agent-device
  agentIp: 'Intranet proxy address',
  agentStartPort: 'start port',
  agentEndPort: 'end port',
  serverPort: 'Service port',
  authenticationKey: 'Authentication token',
  flag: 'flag',
  oldFlag: 'Old agent device',
  newFlag: 'New agent device',
  publicIp: 'External proxy address',
  // ai-device
  hoseAlias: 'node alias',
  ip: 'IP',
  deviceStatus: 'Device state',
  online: 'online',
  offline: 'offline',
  gpuManageId: 'Model',
  createBy: 'Founder',
  image: 'Node image',
  bmc: 'BMC configuration',
  reset: 'reset',
  shutdown: 'shutdown',
  synchronization: 'synchronization',
  gpusetting: 'GPU Configuration',
  monitor: 'Node monitoring',
  listing: 'Listing',
  delist: 'Delist',
  upgrades: 'Upgrades',
  agentDeviceId: 'DeviceIP',
  description:
    'Tip: Train device when deployed. Notice Copy the authentication password to the node service of the training device. After the training device is successfully started, the system automatically changes its status to online.',
  currentState: 'Current state',
  exclusive: 'Exclusive',
  reuse: 'Reuse',
  mode: 'Switch mode',
  migNum: 'Single card reuse /MIG quantity',
  imageDetail: 'Node image details',
  imageName: 'Image name',
  status: 'status',
  inuse: 'inuse',
  unused: 'not used',
  delAllNone: 'Delete all none mirrors',
  delAllUnused: 'Delete all unused mirrors',
  bmcAddress: 'BMC address',
  bmcAccount: 'BMC account',
  bmcPassword: 'BMC password',
  back: 'back',
  basic: 'Basic information',
  accordingTime: 'on time and according to quantity',
  accordingYear: 'monthly contract',
  cpuMsg: 'CPU Information',
  memoryMsg: 'Memory/Hard Disk information',
  gpuMsg: 'GPU Information',
  regionTips:
    ' No data available. Please first select the region, then select the proxy device IP',
  operateSystem: 'operateSystem',
  segmentation: 'segmentation',
  singleGpuSegAmount: 'singleGpuSegAmount',
  // storage-device
  storageType: 'storageType',
  storageIp: 'StorageIP',
  storageStatus: 'storageStatus',
  quotaStatus: 'Quota status',
  enable: 'enable',
  close: 'close',
  storageName: 'StorageName',
  route: 'storagePath',
  calculateDeviceIp: 'Compute node IP',
  nodeDeviceIp: 'Compute node IP',
  // ResourceSpecification
  specificationName: 'name',
  resource: 'available resources',
  laveMemory: 'Memory',
  laveHardDisk: 'hard disk',
  laveCacheDisk: 'cache disk',
  gpuNum: 'arithmetic card',
  cacheDiskMaxLimit: 'Maximum limit of cache disks',
  specificationNum: 'specificationNum',
  singleVideoStorage: 'Single Card VideoStorage',
  deviceType: 'deviceType',
  // areaLimit
  type: 'type',
  userGroup: 'userGroup',
  user: 'user',
  resourceSpecification: 'Resource specification',
  cancel: 'cancel',
  confirm: 'confirm',
  // license
  offlineLicense: 'Offline authorization',
  onlineLicense: 'Online authorization',
  copy: 'copy',
  officialVersion: 'Official version',
  betaVersion: 'beta version',
  open: 'open',
  Shut: 'shut down',
  uploadCertificate: 'Upload certificate',
  uploadTipsOne: 'Drag file here or click Upload',
  uploadTipsTwo: 'Supports only single file uploads at a time',

  maker: 'manufacturer',
  systemWarning: 'System prompt',
  // TaskSystemSetting
  TASK_RELEASE_TIME: ' Task release time (hours)',
  CACHE_RELEASE_TIME: ' Cache disk release time (hours)',
  taskFlag: 'taskSetting',
  useServer: 'identification',
  realNameAuth: 'realNameAuth',
  // certificate
  lapseFlag: ' expired identifier ',
  expired: ' has expired ',
  noexpired: ' not expired ',
  lapseTime: ' failure time ',
  normal: ' enabled ',
  certFile: ' Certificate file ',
  keyFile: ' key file ',

  // ssh
  remark: 'Remark ',
  alias: 'alias ',
  publicKey: 'public key ',
  rules: {
    region: ' Please enter a region name ',
    model: ' Please enter the computing card model number ',
    videoStorage: ' Please enter video memory ',
    floatingPointComputingPower:
      ' Please enter floating-point computing power ',
    cudaMaxVersion: ' Please enter the highest supported Toolkit version ',
    makerId: ' Please enter manufacturer ',
    regionId: ' Please select a region ',
    agentIp: ' Please enter the Intranet proxy address',
    correctIp: 'Please enter the correct proxy address',
    agentStartPort: ' Please enter the agent start port ',
    agentEndPort: ' Please enter the agent end port ',
    serverPort: ' Please enter the service port ',
    authenticationKey: ' Please enter the authentication token',
    flag: 'Please select a flag',
    mode: ' Please select mode ',
    migNum: ' Please enter the number of MIGs ',
    migLimit: ' In exclusive mode, the number of MIGs can only be entered 1',
    hoseAlias: ' Please enter a node alias ',
    ip: ' Please enter IP address ',
    ipRule: 'IP address error ',
    gpuManageId: ' Please select computing card model ',
    agentDeviceId: ' Please select the region and then the agent device IP',
    bmcAccount: ' Please enter BMC account ',
    bmcPassword: ' Please enter BMC password ',

    storageName: ' Please enter a storage name ',
    storageIp: ' Please enter storage IP',
    type: ' Please select storage type ',
    route: ' Please enter the storage path ',
    calculateDeviceId: ' Please select computing device ',

    specificationName: ' Please enter a resource specification name ',
    status: ' Please select status ',
    cpuCoreNum: ' Please enter CPU',
    cpuCoreNumLimit:
      ' The number of cpus exceeds the remaining resources, please re-enter ',
    gpuNum: ' Please enter GPU',
    gpuNumLimit:
      ' The number of Gpus exceeds the remaining resources, please re-enter ',
    input: ' Please enter ',
    hardDiskLimit: ' Hard disk exceeds remaining resources, please re-enter ',
    memory: ' Please enter memory ',
    memoryLimit: ' Memory exceeds remaining resources, please re-enter ',
    cacheDiskMax: ' Please enter a cache disk ',
    cacheDiskMaxLimit:
      ' Cache disk exceeds remaining resources, please re-enter ',
    specificationNum: ' Please enter specificationnum ',
    overCpuCoreNum:
      ' The number of cpus exceeds the remaining amount of the resource share, please re-enter ',
    overGpuNum:
      ' The number of Gpus has exceeded the remaining amount of resource share, please re-enter ',
    overMemory:
      ' Memory has exceeded the remaining amount of resource share, please re-enter ',
    overHardDisk:
      ' The hard disk has exceeded the remaining amount of the resource share, please re-enter ',
    overCacheDiskMaxLimit:
      'The cache disk has exceeded the remaining amount of the resource quota, please re-enter',

    userId: ' Please select user ',
    poolId: ' Please select a resource pool ',

    isGateway: ' Please enter area gateway ',
    port: ' Please enter port ',
    ipAddress: ' Please enter IP',
    authKey: ' Please enter authentication key',
    delFlag: ' Does not match the required input identifier, please re-enter ',
    delGpu: ' About to delete the following arithmetic card ',
    delTips: ' If you are sure you want to delete, enter ',
    delDevice: 'The following devices are to be deleted',
    operateSystem: 'Please select operateSystem',
    useServer: 'Please select service identification',
    toolkitVersion: 'Please enter Toolkit',
    quotaStatus: 'Please select quotaStatus',
    singleGpuSegAmount: ' Please enter the number of slicing ',

    alias: 'Please enter an alias ',
    publicKey: 'Please enter public key'
  }
}
export { maintenanceCenterZh, maintenanceCenterEn }
