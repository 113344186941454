
import { defineComponent, onMounted, reactive, nextTick, ref, onBeforeMount } from 'vue'
import { useColorStore } from '@/store/colorPicker'
import changeColor from '@/utils/changeColor'
import { getStoredColor } from '@/utils/colorStorage'
import { userStore } from '@/store/user'
export default defineComponent({
  name: 'ColorPicker',
  props: {
    distanceRight: {
      type: Number,
      default: 36
    },
    distanceBottom: {
      type: Number,
      default: 100
    },
    isScrollHidden: {
      type: Boolean,
      default: false
    },
    isCanDraggable: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const user = userStore()
    const store = useColorStore()
    const selectedColor = ref('#4984FD')
    const checkedColor = ref()
    const checkedIndex = ref()
    const colorGroup = ref(['#4984FD', '#F55158', '#01BDE3', '#E9B025'])
    const colorVisible = ref(false)
    const dragging = ref(false)
    const data = reactive({
      clientWidth: 0,
      clientHeight: 0,
      left: 0,
      top: 0,
      right: 0,
      timer: null,
      currentTop: 0,
      mousedownX: 0,
      mousedownY: 0
    })
    const floatDragDom = ref()
    data.clientWidth = document.documentElement.clientWidth
    data.clientHeight = document.documentElement.clientHeight
    onMounted(() => {
      if (getStoredColor()) {
        changeColor(Number(getStoredColor()))
        checkedColor.value = selectedColor.value = colorGroup.value[getStoredColor()]
      } else {
        changeColor(Number(0))
        checkedColor.value = selectedColor.value = colorGroup.value[0]
      }
      props.isCanDraggable &&
        nextTick(() => {
          // 获取元素位置属性
          floatDragDom.value = document.getElementById('floatDrag') // 设置初始位置
          data.right = props.distanceRight - 40
          data.top =
            Number(data.clientHeight) -
            floatDragDom.value?.offsetHeight -
            props.distanceBottom -
            400
          initDraggable()
        })
      window.addEventListener('resize', handleResize)
    })
    onBeforeMount(() => {
      window.removeEventListener('resize', handleResize)
    })
    /**
     * 初始化draggable
     */
    const initDraggable = () => {
      floatDragDom.value.addEventListener('touchstart', toucheStart)
      floatDragDom.value.addEventListener('touchmove', (e:any) => touchMove(e))
      floatDragDom.value.addEventListener('touchend', touchEnd)
    }
    const handleResize = () => {
      checkDraggablePosition()
    }
    /**
     * 判断元素显示位置
     * 在窗口改变和move end时调用
     */
    const checkDraggablePosition = () => {
      data.clientWidth = document.documentElement.clientWidth
      data.clientHeight = document.documentElement.clientHeight
      if (data.right + floatDragDom.value.offsetWidth / 2 >= data.clientWidth / 2) {
        // 判断位置是往左往右滑动
        data.right = data.clientWidth - floatDragDom.value.offsetWidth
      } else {
        data.right = 0
      }
      if (data.top < 0) {
        // 判断是否超出屏幕上沿
        data.top = 0
      }
      if (data.top + floatDragDom.value.offsetHeight >= data.clientHeight) {
        // 判断是否超出屏幕下沿
        data.top = data.clientHeight - floatDragDom.value.offsetHeight
      }
    }
    const canClick = ref(false)
    const mouseDown = (e: any) => {
      const event = e || window.event
      data.mousedownX = event.screenX
      data.mousedownY = event.screenY
      const floatDragWidth = floatDragDom.value.offsetWidth / 2
      const floatDragHeight = floatDragDom.value.offsetHeight / 2
      if (event.preventDefault) {
        event.preventDefault()
        colorVisible.value = false
      }
      canClick.value = false
      floatDragDom.value.style.transition = 'none'
      document.onmousemove = function (e) {
        // var events = e || window.event // 这里的判断是为了保证按钮只能在浏览器内拖动，不会超出
        data.right = Number(data.clientWidth) - e.clientX - floatDragWidth
        data.top = e.clientY - floatDragHeight
        if (data.right < 0) data.right = 0
        if (data.top < 0) data.top = 0 // 鼠标移出可视区域后给按钮还原
        if (
          e.clientY < 0 ||
          e.clientY > Number(data.clientHeight) ||
          e.clientX > Number(data.clientWidth) ||
          e.clientX < 0
        ) {
          data.right = 0
          data.top =
            Number(data.clientHeight) -
            floatDragDom.value?.offsetHeight -
            props.distanceBottom
          document.onmousemove = null
          floatDragDom.value.style.transition = 'all 0.3s'
          return
        }
        if (data.right >= document.documentElement.clientWidth - floatDragWidth * 2) {
          data.right = document.documentElement.clientWidth - floatDragWidth * 2
        }
        if (data.top >= Number(data.clientHeight) - floatDragHeight * 2) {
          data.top = Number(data.clientHeight) - floatDragHeight * 2
        }
      }
      dragging.value = true
    }
    const mouseUp = (e: any) => {
      const event = e || window.event // 判断只是单纯的点击，没有拖拽
      if (data.mousedownY === event.screenY && data.mousedownX === event.screenX) {
        dragging.value = false
        colorVisible.value = true
      }
      document.onmousemove = null
      checkDraggablePosition()
      floatDragDom.value.style.transition = 'all 0.3s'
    }
    const toucheStart = () => {
      canClick.value = false
      floatDragDom.value.style.transition = 'none'
    }
    const touchMove = (e:any) => {
      canClick.value = true
      if (e.targetTouches.length === 1) {
        // 单指拖动
        const touch = e.targetTouches[0]
        data.right =
          Number(data.clientWidth) - touch.clientX - floatDragDom.value.offsetWidth / 2
        data.top = touch.clientY - floatDragDom.value.offsetHeight / 2
      }
    }
    const touchEnd = () => {
      colorVisible.value = false
      if (!canClick.value) return // 解决点击事件和touch事件冲突的问题
      floatDragDom.value.style.transition = 'all 0.3s'
      checkDraggablePosition()
    }
    function selectColor (color:string, index:number) {
      checkedIndex.value = index
      selectedColor.value = color
    }
    function handleConfirm () {
      checkedColor.value = selectedColor.value
      changeColor(checkedIndex.value)
      user.setThemeColor(checkedIndex.value)
      colorVisible.value = false
    }
    const handleClickChange = () => {
      if (dragging.value) {
        colorVisible.value = false
      }
    }
    return {
      data,
      handleResize,
      checkDraggablePosition,
      mouseUp,
      mouseDown,
      selectedColor,
      colorGroup,
      selectColor,
      handleConfirm,
      colorVisible,
      checkedColor,
      store,
      handleClickChange
    }
  }
})
