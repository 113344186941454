const headNavigationZh = {
  computationalPowerMarket: '算力市场',
  cost: '费用',
  laborOrder: '工单',
  console: '控制台',
  basicSettings: '基本信息',
  securitySetting: '安全设置',
  helpCenter: '帮助中心',
  logOut: '退出',
  productsAndServices: '产品与服务',
  login: '登录',
  register: '免费注册',
  logOutTips: '是否确认退出平台',
  delSuccess: '删除成功',
  collectionSuccess: '收藏成功',
  recentlyVisited: '最近访问',
  myCollection: '我的收藏',
  inputPlaceholder: '请输入关键词',
  notification: '通知信息',
  viewMore: '查看更多',
  all: '全部',
  unread: '未读',
  allRead: '全部已读',
  noMessage: '暂无新信息'
}

const headNavigationEN = {
  computationalPowerMarket: 'computational power market',
  cost: 'cost',
  laborOrder: 'labor order',
  console: 'console',
  basicSettings: 'basic settings',
  securitySetting: 'security setting',
  helpCenter: 'help center',
  logOut: 'log out',
  productsAndServices: 'products and services',
  login: 'login',
  register: 'register',
  logOutTips: 'Are you sure to exit the platform',
  delSuccess: 'del success',
  collectionSuccess: 'collection success',
  recentlyVisited: 'recently visited',
  myCollection: 'my collection',
  inputPlaceholder: 'Please enter a directory name',
  notification: 'Notification Information',
  viewMore: 'View more',
  all: 'all',
  unread: 'Unread',
  allRead: 'all Read',
  noMessage: 'no message'
}

export {
  headNavigationZh,
  headNavigationEN
}
