
import { defineComponent, ref, watch } from 'vue'
import ColorPicker from '@/components/color-picker/ColorPicker.vue'
import DownloadSquare from '@/components/download-square/DownloadSquare.vue'
import GlobalUploader from '@/components/global-uploader/GlobalUploader.vue'
import { useColorStore } from '@/store/colorPicker'
import enUS from 'ant-design-vue/es/locale/en_US'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('en')
export default defineComponent({
  components: {
    ColorPicker,
    DownloadSquare,
    GlobalUploader
  },
  name: 'App',
  setup () {
    const store = useColorStore()
    const locale = ref(zhCN.locale)
    watch(locale, val => {
      dayjs.locale(val)
    })

    return {
      locale,
      zhCN,
      enUS,
      store
    }
  }
})
