const myTaskZh = {
  taskId: '实例ID',
  taskName: '实例名称',
  inputPlaceholder: '请输入',
  selectPlaceholder: '请选择',
  runningHost: '运行主机',
  costType: '计费模式',
  accordingTime: '按时按量',
  accordingDay: '包天',
  accordingMonth: '包月',
  accordingYear: '包年',
  taskStatus: '实例状态',
  imageDownload: '镜像下载中',
  imagePacking: '镜像打包中',
  creating: '创建中',
  running: '运行中',
  shutDown: '关机中',
  powerOff: '已关机',
  useless: '不可用',
  release: '释放中',
  nocard: '无卡模式',
  starting: '开机中',
  reseting: '重启中',
  nocardStart: '无卡开机中',
  resetSystem: '重置系统中',
  createBy: '所属用户',
  search: '搜索',
  addTask: '创建实例',
  offline: '关机',
  reset: '重启',
  powerOn: '开机',
  onRelease: '释放',
  cardIdle: '运算卡空闲',
  cardOccupancy: '运算卡占用',
  core: '核',
  memory: '内存',
  gpuModel: '运算卡',
  taskStorage: '实例存储',
  hardDisk: '系统盘',
  cache: '缓存盘',
  onlineRate: '网络带宽',
  uplinkRate: '上行带宽',
  downlinkRate: '下行带宽',
  orderPrice: '价格',
  calculateDeviceIp: '节点IP',
  more: '更多',
  loginSSh: '登录命令',
  copy: '复制',
  password: '密码',
  ssh: 'SSH地址',
  sshPwd: 'SSH密码',
  vscode: 'VSCode地址',
  open: '打开',
  vscodePasswd: 'VSCode密码',
  tensorboard: 'Tensorboard地址',
  customPort: '自定义地址',
  single: '单机',
  multiple: '多机',
  changeFee: '计费变更',
  upgrading: '升配中',
  imageChange: '更换镜像',
  saveImage: '保存镜像',
  primaryImage: '原镜像',
  workMonitor: '作业监控',
  cacheExpanse: '缓存盘扩容',
  moveTask: '实例迁移',
  operateLog: '操作记录',
  changeStatus: '状态更换',
  imageName: '镜像名称',
  description: '描述',
  normal: '正常',
  hostexception: '主机服务异常',
  netexception: '网络异常',
  linkRate: '带宽值',
  freeBandwidth: '当前免费带宽',
  hour: '小时',
  delayTime: '延长时间',

  resetSys: '重置系统',
  bandwidthExpansion: '带宽扩容',
  caseAllocation: '实例升配',
  renewInstance: '实例续租',
  operatingTime: '操作时间',
  action: '动作',
  createByTask: '操作人',
  rules: {
    imageName: '请输入镜像名称',
    imageNameRule: '镜像名称由小写字符和数字组成,且不可少于4位,不可多于10位',
    description: '描述由中英文及数字组成,且不可少于2位,不可多于50位'
  },
  columns: {
    taskId: '实例ID/名称',
    status: '实例状态',
    hostStatus: '主机状态',
    gpuModel: '资源规格',
    runnindMode: '运行模式',
    costType: '计费模式',
    userSubName: '子用户',
    region: '区域',
    phone: '所属用户',
    releaseTime: '释放时间',
    note: '备注',
    tools: '便捷工具',
    operation: '操作'
  },

  myTask: '我的实例',
  checkDetail: '查看详情',
  dailyFormat: '包天包月包年',
  expireTask: '即将过期',
  releaseTask: '即将释放',
  netDisk: '我的网盘',
  myStorage: '我的存储',
  zoneFormat: '已用空间/总空间',
  myCache: '我的缓存',
  containerImage: '容器镜像',
  myImage: '我的镜像',
  othersShare: '他人共享',
  sharing: '共享中',
  systemImage: '系统镜像',
  delResources: '即将释放资源',
  expireResources: '7日内到期资源',
  personCenter: '个人中心',
  masterAccount: '主账号',
  subAccount: '子账号',
  user: '用户',
  checkMore: '查看更多',
  useBalance: '可用余额',
  stationMsgs: '站内信',
  notice: '通知',
  noticeTips: '绑定微信，查看更多通知消息和平台优惠活动',
  goBind: '去绑定',
  pieces: '张'

}

const myTaskEN = {
  taskId: 'Task ID',
  taskName: 'Task name',
  inputPlaceholder: 'Please enter',
  selectPlaceholder: 'Please select',
  runningHost: 'Run the host',
  costType: 'Billing model',
  accordingTime: 'on time and according to quantity',
  accordingDay: 'to wrap the sky',
  accordingMonth: 'To pay a monthly fee',
  accordingYear: 'Package year',
  taskStatus: 'Task status',
  imageDownload: 'Image download',
  imagePacking: 'image packing',
  creating: 'Creating',
  running: 'running',
  shutDown: 'shutDown',
  powerOff: 'off',
  useless: 'useless',
  release: 'on release',
  starting: 'Starting up',
  reseting: 'rebooting',
  nocardStart: 'Boot without card mode',
  createBy: 'Owning user',
  search: 'Search',
  addTask: 'Create task',
  offline: 'Off',
  reset: 'reset',
  powerOn: 'Turn on',
  onRelease: 'Release',
  cardIdle: 'idle',
  cardOccupancy: 'occupancy',
  core: 'core',
  memory: 'memory',
  gpuModel: 'arithmetic card',
  taskStorage: 'task storage',
  hardDisk: 'system disk',
  cache: 'cache disk',
  onlineRate: 'network bandwidth ',
  uplinkRate: 'uplink bandwidth',
  downlinkRate: 'downlink bandwidth ',
  orderPrice: 'Price ',
  calculateDeviceIp: 'Node IP',
  more: 'more ',
  loginSSh: 'login command ',
  copy: 'copy ',
  password: 'Password ',
  ssh: 'SSH address ',
  sshPwd: 'SSH password ',
  vscode: 'VSCodeAddress ',
  open: 'open ',
  vscodePasswd: 'VSCodePassword ',
  tensorboard: 'TensorboardAddress ',
  customPort: 'CustomAddress ',
  single: 'single',
  multiple: 'multiple',
  nocard: 'No card mode ',
  changeFee: 'Charge change ',
  resetSystem: 'Reset the system ',
  upgrading: 'Upgrading',
  imageChange: 'Replace image ',
  saveImage: 'Save image ',
  primaryImage: 'primaryImage',
  workMonitor: 'Job monitoring ',
  cacheExpanse: 'CacheDiskExpansion',
  moveTask: 'Task migration ',
  operateLog: 'operation record ',
  changeStatus: 'Status change ',
  imageName: 'Image name ',
  description: 'description',
  normal: 'normal',
  hostexception: 'hostException',
  netexception: 'netException',
  linkRate: 'linkRate',
  freeBandwidth: 'freeBandwidth',
  hour: 'hour',
  delayTime: 'delayTime',

  resetSys: 'Reset the system ',
  bandwidthExpansion: ' BandwidthExpansion',
  caseAllocation: 'instance allocation ',
  renewInstance: 'Instance renewal ',
  operatingTime: 'operation time ',
  action: 'action ',
  createByTask: ' Operator ',
  rules: {
    imageName: ' Please enter an image name ',
    imageNameRule:
      'Image name consists of lowercase characters and numbers, and must not be less than 4 characters,not be more than 10 characters',
    description:
      'The description should consist of no less than 2 characters and no more than 50 characters'
  },
  columns: {
    taskId: 'instance ID/ name ',
    status: 'Instance status ',
    hostStatus: 'Host status ',
    gpuModel: 'Resource Specification ',
    runnindMode: 'Run mode ',
    costType: 'Billing mode ',
    userSubName: 'subuser ',
    region: 'region ',
    phone: 'Owning user ',
    releaseTime: 'Release time ',
    note: 'Note ',
    tools: 'Convenient tools ',
    operation: 'Operation '
  },
  myTask: 'My instance ',
  checkDetail: 'See details ',
  dailyFormat: 'Package day package month package year ',
  expireTask: 'about to expire ',
  releaseTask: 'Soon to be released ',
  netDisk: 'NetDisk ',
  myStorage: 'My storage ',
  zoneFormat: 'Used/total space ',
  myCache: 'My cache ',
  containerImage: 'Image ',
  myImage: 'My mirror image ',
  othersShare: 'Others share ',
  sharing: 'In the sharing ',
  systemImage: 'System image',
  delResources: 'Resources to be deleted ',
  expireResources: 'Resources expire within 7 days ',
  personCenter: 'Personal center ',
  masterAccount: 'Master account',
  subAccount: 'subaccount ',
  user: 'user ',
  checkMore: 'See more ',
  useBalance: 'available balance ',
  stationMsgs: 'in-station letters ',
  notice: 'notice',
  noticeTips: 'Bind to wechat to view more notification messages and platform promotions ',
  goBind: ' unbind ',
  pieces: 'pieces'
}
export { myTaskZh, myTaskEN }
