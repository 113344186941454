const userCenterZh = {
  // user
  username: '用户名',
  phone: '手机号',
  inviteUser: '邀请人',
  saleName: '销售',
  status: '用户状态',
  reviewed: '待审核',
  normal: '正常',
  frozen: '冻结',
  certificate: '等待认证',
  forceLogin: '强制登录',
  search: '搜索',
  allUsers: '全部用户',
  add: '添加',
  review: '审核',
  recharge: '充值',
  deduct: '扣款',
  bindSale: '关联销售',
  cancelSale: '解除关联销售',
  del: '删除',
  export: '导出',
  patchImport: '批量导入',
  resetPwd: '重置密码',
  download: '下载导入模板',
  unverified: '未认证',
  studentCertification: '学生认证',
  enterpriseCertification: '企业认证',
  chooseSales: '选择销售',
  money: '充值金额',
  account: '用户账号',
  email: '用户邮箱',
  password: '用户密码',
  import: '导入',
  uploadText: '将文件拖到此处或点击上传',
  uploadHint: '单次仅支持单个文件上传',
  userNewPassword: '所选用户新密码',
  verifyText: '校验信息',
  loginPwd: '您的登录密码',
  createTime: '创建时间',
  checkAll: '全选',
  cancel: '取消',
  confirm: '确认',
  // userLevel
  edit: '编辑',
  synchronousUser: '同步用户',
  allConditions: '达成所有条件',
  anyConditions: '达成任何条件',
  need: '需要',
  noneed: '不需要',
  userLevel: '用户等级',
  gradeSort: '等级排序',
  gradeName: '等级名称',
  upgradeRules: '升级规则',
  escalationCondition: '升级条件',
  meetStudentCertification: '满足学生认证',
  meetEnterpriseCertification: '满足企业认证',
  userRecharge: '用户充值',
  cumulativeConsumption: '累计消费',
  inviteAccount: '邀请人数',
  updateTime: '更新时间',
  // basic
  copy: '复制',
  basic: '基本信息',
  name: '姓名',
  identificationNumber: '证件号码',
  schooling: '学历',
  businessInformation: '业务信息',
  occupation: '职业',
  studyDirection: '研究方向',
  trade: '主要应用行业',
  awards: '大赛奖项',
  awardsPlaceHolder: '请输入大赛奖项',
  contactInformation: '联系方式',
  area: '国家/地区',
  china: '中国',
  country: '所在地区',
  save: '保存',
  uploadPic: '上传头像',
  changeGroup: '更换用户组',
  // Security
  loginPasswords: '登录密码',
  safeTips: '安全性高的密码可以使账号更安全。建议您定期更换密码',
  setting: '已设置',
  unset: '未设置',
  editPwd: '修改密码',
  findPwd: '忘记密码',
  bindPhone: '手机绑定',
  bindPhoneTipsOne: '您已绑定了手机',
  bindPhoneTipsTwo: '【您的手机号可以直接用于登录、找回密码等】',
  changePhone: '更换手机',
  bindWechat: '微信绑定',
  bindWechatTips: '绑定微信增强账号安全，并享受更多平台权益。',
  binding: '绑定微信',
  unbind: '解绑微信',
  safeEmail: '安全邮箱',
  safeEmailTips:
    "安全邮箱可以作为账号身份验证的一种辅助校验方式。如果收不到邮件，可检查垃圾邮件，并将scm{'@'}aiserver.cn添加为白名单",
  bindEmail: '绑定邮箱',
  oldPassword: '旧密码',
  newPassword: '新密码',
  oldPhoneCode: '旧手机验证码',
  sendCode: '发送验证码',
  sendTips: '秒后重新发送',
  newPhone: '新手机号码',
  code: '验证码',
  changeEmail: '更换邮箱',
  oldCode: '旧邮箱验证码',
  newEmail: '新邮箱号',
  scanTips: '请使用微信扫码绑定',
  nameAuthentication: '实名认证',
  nameTips: '实名认证后可以使用平台更完整的功能，如打开实例的自定义服务等',
  authenticated: '已认证',
  noAuthenticated: '未认证',
  immediateAuthentication: '立即认证',
  // Invite
  inviteTips: '邀请好友注册立得优惠券',
  inviteTipsOne: '将邀请链接分享给好友',
  inviteTipsTwo: '好友完成注册并累计充值100元',
  inviteTipsThree: '邀请成功立即获得邀请奖励',
  ownLink: '您的专属链接',
  guideTips: '请引导好友在分享链接页面直接注册，否则不能获得奖励',
  gainMoney: '已获得奖励',
  gainInvite: '已成功邀请',
  imgType: '仅支持上传jpg、png、jpeg图片格式',
  uploadyinye: '请上传营业执照照片',
  uploadStudent: '请上传学生证照片',
  agreeReal: '请勾选同意真实性声明',
  okCompany: '企业认证成功',
  okStudent: '学生认证成功',
  schoolTimeErrMsg: '请选择在校时间',

  inviteCode: '用户邀请码',
  replace: '解绑',
  bind: '绑定',
  wechat: '微信',
  emailTitle: '邮箱',
  realNameErrMsg: '请输入真实姓名',
  schoolLocationErrmsg: '请输入学校所在地',
  schoolErrMsg: '请输入学校',
  collageErrMsg: '请输入学院',
  majorErrMsg: '请输入专业',
  onlyChinese: '仅支持输入中文',

  // userGroup
  groupName: '用户组名称',
  leaderName: '直属领导姓名',
  leaderPhone: '直属领导手机号',
  documentType: '证件类型',

  // certificationAudit
  auditType: '认证类型',
  personalCertification: '个人认证',
  personalIdentityAuthentication: '个人身份认证',
  enterpriseIDAuthentication: '企业证件认证',
  studentIDAuthentication: '学生证件认证',
  detail: '详情',
  authStatus: '状态',
  auditFailure: '审核失败',
  verified: '审核通过',
  certCode: '身份证号码',
  certCodeFrontUrl: '身份证正面',
  certCodeBackUrl: '身份证反面',
  pass: '通过',
  reject: '驳回',
  remark: '原因',
  realName: '学生姓名',
  schoolName: '学校名称',
  school: '学校',
  college: '学院',
  education: '学历',
  major: '专业',
  schoolTime: '在校时间',
  schoolLocation: '学校所在地',
  startSchoolTime: '在校开始时间',
  endSchoolTime: '在校结束时间',
  studentCard: '学生证',
  studentTips: '请上传学生证信息页，需包含姓名、学号、证件有效期、学校名等信息',
  compName: '公司名称',
  creditCode: '统一社会信用代码',
  license: '营业执照',
  applyTime: '申请时间',
  operate: '操作',
  type: '类型',
  walletMoney: '金额',
  adminAmount: '后台充值（元）',
  availableBalance: '用户余额（元）',
  idCardEmptyFront: '请上传身份证正面照片',
  idCardEmptyEnd: '请上传身份证反面照片',
  ok: '用户认证成功',
  cardErrMsg: '请输入证件号码',
  onlyUpN: '仅支持输入大写字母和数字',
  rules: {
    account: '请输入账号',
    email: '请输入邮箱',
    emailRule: '邮箱格式不正确',
    emailRegister: '邮箱已注册，请更换其它邮箱',
    password: '请输入密码',
    phone: '请输入手机号',
    username: '请输入用户昵称',
    money: '请输入充值金额',
    phoneRule: '手机号码格式不正确',
    phoneRegister: '手机号已注册，请更换其它手机号',

    gradeSort: '请输入等级排序',
    gradeName: '请输入等级名称',
    upgradeRules: '请选择等级规则',
    input: '请输入',

    checkStr: '由中英文或数字组成且至少字符长度区间为1-12',
    checkDoubleStr: '由中英文或数字组成且至少字符长度区间为1-24',
    checkTribleStr: '由中英文或数字组成且至少字符长度区间为1-48',
    checkImg: '图片类型必须是jpeg,jpg,png中的一种',

    newPassword: '请输入新密码',
    oldPassword: '请输入旧密码',
    accountLimit: '只可输入大小写字母和数字,且不可少于2个字符',
    accountLen: '输入不可超过30个字符',

    groupName: '请输入用户组名称',
    leaderName: '请输入直属领导姓名',
    status: '请选择状态',

    type: '请选择类型',
    walletMoney: '请输入金额'
  },
  nameErrMsg: '请输入姓名',
  nameErrTypeMsg: '姓名仅支持中文，且长度为2到4个字符',
  idCardErrMsg: '请输入身份证号码',
  idCardTypeErrMsg: '身份证号格式错误，请重新输入',
  educationErrMsg: '请输入学历',
  occupationErrMsg: '请输入职业',
  studydirectionErrMsg: '请输入研究方向',
  mainApplicationIndustryErrMsg: '请输入应用行业',
  localAreaErrMsg: '请输入所在地区',
  pictureUpload: '头像上传',
  realNameAuthentication: '实名认证',
  idCard: '身份证照片',
  frontofIDcard: '身份证正面',
  onthebackoftheIDcard: '身份证反面',
  submitapplication: '提交申请',
  companyErrMsg: '请输入公司名称',
  companyTypeErrMsg: '公司名称仅支持中文',
  creditCodeErrMsg: '请输入统一社会信用代码',
  creditTypeCodeErrMsg: '仅支持输入大写字母和数字',
  companyAgree: '同意真实性声明'
}
const userCenterEn = {
  // user
  username: 'User name',
  phone: 'mobile number',
  inviteUser: 'Inviter',
  saleName: 'Sell',
  status: 'User status',
  reviewed: 'to be reviewed',
  normal: 'Normal',
  frozen: 'frozen',
  certificate: 'pending certification',
  forceLogin: 'Forced landing',
  search: 'Search',
  allUsers: 'All users',
  add: 'add',
  review: 'Review',
  recharge: 'Recharge',
  deduct: 'Deduct money',
  bindSale: 'Related sale',
  cancelSale: 'Unaffiliate sales',
  del: 'Delete',
  idCard: 'ID card photo',
  export: 'export',
  patchImport: 'Batch import',
  resetPwd: 'Reset password',
  download: 'Download import template',
  unverified: 'unverified',
  studentCertification: 'Student certification',
  enterpriseCertification: 'Enterprise Certification',
  chooseSales: 'Choose to sell',
  money: 'Recharge amount',
  identificationNumber: 'ID',
  account: 'account',
  email: 'Email',
  password: 'password',
  import: 'import',
  uploadText: 'Drag file here or click Upload',
  uploadHint: 'Supports only a single file upload at a time',
  userNewPassword: 'New password of the selected user',
  verifyText: 'Check information',
  loginPwd: 'Your password',
  createTime: 'createTime',
  checkAll: 'checkAll',
  documentType: 'Document type',
  cancel: 'cancel',
  confirm: 'confirm',
  // userLevel
  edit: 'Edit',
  synchronousUser: 'Sync user',
  allConditions: 'AllConditions',
  anyConditions: 'Anyconditions',
  need: 'need',
  noneed: 'No need',
  userLevel: 'User level',
  gradeSort: 'rank sort',
  gradeName: 'level name',
  upgradeRules: 'Upgrade Rules',
  escalationCondition: 'Upgrade condition',
  meetStudentCertification: 'Meet student Certification',
  meetEnterpriseCertification: 'satisfy the business enterprise certification',
  userRecharge: 'Recharge',
  cumulativeConsumption: 'cumulative consumption',
  inviteAccount: 'Number of invitees',
  updateTime: 'updateTime',
  realNameErrMsg: 'Please enter your real name',
  schoolLocationErrmsg: 'Please enter the location of the school',
  schoolErrMsg: 'Please enter the school',
  collageErrMsg: 'Please enter the college',
  majorErrMsg: 'Please enter the major',
  onlyChinese: 'Only supports Chinese input',
  cardErrMsg: 'Please enter your identification number',
  onlyUpN: 'Only supports inputting uppercase letters and numbers',
  // basic
  copy: 'copy',
  basic: 'Basic information',
  name: 'name',
  schooling: 'education',
  businessInformation: 'Business information',
  occupation: 'occupation',
  studyDirection: 'Studydirection',
  trade: 'Main application industry',
  awards: 'Competition Awards',
  awardsPlaceHolder: 'Please enter contest award',
  contactInformation: 'Contact information',
  area: 'country/region',
  china: 'China',
  country: 'local area',
  save: 'save',
  uploadPic: 'Upload avatar',
  changeGroup: 'changeUserGroup',
  ok: 'User authentication successful',
  // Security
  loginPasswords: 'Login password',
  safeTips:
    'A strong password makes your account more secure. It is recommended that you change your password regularly',
  setting: 'set',
  unset: 'not set',
  editPwd: 'Change password',
  findPwd: 'Forgot password',
  bindPhone: 'mobile phone binding',
  bindPhoneTipsOne: 'You are already tied to your phone',
  bindPhoneTipsTwo:
    '【 Your mobile phone number can be directly used to log in, retrieve password, etc. 】',
  changePhone: 'Change your phone',
  bindWechat: 'wechat binding',
  bindWechatTips:
    'Bind to wechat to enhance account security and enjoy more platform rights.',
  binding: 'Bind to wechat',
  unbind: 'Unbind wechat',
  safeEmail: 'Safe email',
  safeEmailTips:
    "Secure email can be used as an auxiliary verification method for account authentication. If you do not get it, check for spam and whitelist scm{'@'}aiserver.cn",
  bindEmail: 'Bind mailbox',
  oldPassword: 'oldPassword',
  newPassword: 'newPassword',
  oldPhoneCode: 'oldPhoneCode',
  sendCode: 'Send Code',
  sendTips: 'Resend after seconds',
  newPhone: 'newPhone',
  code: 'code',
  changeEmail: 'Change email address',
  oldCode: 'oldEmailCode',
  newEmail: 'newEmail',
  scanTips: 'Please use wechat scan code binding',
  nameAuthentication: ' Real name authentication ',
  nameTips:
    ' After real name authentication, you can use more complete features of the platform, such as opening custom services of the instance ',
  authenticated: ' authenticated ',
  noAuthenticated: ' not authenticated ',
  immediateAuthentication: ' Immediate authentication ',
  // Invite
  inviteTips: 'Invite friends to sign up for Instant Coupons',
  inviteTipsOne: 'Share the invitation link with friends',
  inviteTipsTwo: 'Friends complete registration and recharge 100 yuan in total',
  inviteTipsThree:
    'Get Invitation Reward immediately for successful invitation',
  ownLink: 'Your own link',
  guideTips:
    'Please direct your friends to sign up directly on the Share link page, otherwise you won\'t get rewards',
  gainMoney: 'Awarded',
  gainInvite: 'Successfully invited',

  inviteCode: 'inviteCode',
  // userGroup
  groupName: ' User group name ',
  leaderName: 'leaderName',
  leaderPhone: 'leaderPhone',

  // certificationAudit
  auditType: ' Authentication type ',
  personalCertification: ' Personal Certification ',
  personalIdentityAuthentication: 'Personal identity authentication',
  enterpriseIDAuthentication: 'Enterprise ID authentication',
  studentIDAuthentication: 'Student ID authentication',
  detail: 'detail',
  authStatus: ' status ',
  auditFailure: ' Audit failure ',
  verified: ' approved ',
  certCode: ' ID number ',
  certCodeFrontUrl: ' ID card front ',
  certCodeBackUrl: ' Reverse ID card ',
  pass: ' pass ',
  reject: ' Reject ',
  remark: ' Cause ',
  realName: ' Student name ',
  schoolName: ' School name ',
  school: 'school',
  college: ' College ',
  education: 'education ',
  major: ' Major ',
  schoolTime: 'school time',
  schoolLocation: ' Schoollocation ',
  startSchoolTime: ' Startschooltime ',
  endSchoolTime: 'Endschooltime',
  studentCard: ' Student card ',
  studentTips: 'Please upload the student ID information page, which should include information such as name, student ID, ID validity period, and school name',
  compName: ' Company name ',
  creditCode: 'Social Credit Code ',
  license: ' Business license ',
  applyTime: ' request time ',
  operate: ' Operate ',

  type: 'Type ',
  walletMoney: 'money',
  adminAmount: 'Background recharge (yuan) ',
  availableBalance: ' User balance (yuan) ',
  rules: {
    account: 'Please enter an account number',
    email: 'Please enter email',
    emailRule: 'Email format is incorrect',
    emailRegister: 'Email has been registered, please change to another email',
    password: 'Please enter password',
    phone: 'Please enter the phone number',
    username: 'Please enter a user nickname',
    money: 'Please enter the recharge amount',
    phoneRule: 'Mobile number format is incorrect',
    phoneRegister:
      'The phone number has been registered, please change another phone number',

    gradeSort: 'Please enter rank sort',
    gradeName: 'Please enter a grade name',
    upgradeRules: 'Please select grade rules',
    input: 'Please enter',

    checkStr:
      'Must be at least 1-12 characters long in Chinese and English or numerals',
    checkDoubleStr:
      'Composed of Chinese and English or numbers and at least 1-24 characters in length',
    checkTribleStr:
      'Must be at least 1-48 characters long in Chinese and English or numerals',
    checkImg: 'Image type must be one of jpeg,jpg,png',

    newPassword: 'Please enter a new password',
    oldPassword: 'Please enter old password',

    accountLimit:
      'Only uppercase and lowercase letters and numbers can be entered and must be no less than 2 characters',
    accountLen: 'Input cannot exceed 30 characters',

    groupName: ' Please enter a user group name ',
    leaderName: ' Please enter your direct leader\'s name ',
    status: ' Please select the status',

    type: 'Please select a type ',
    walletMoney: 'Please enter the amount '
  },
  nameErrMsg: 'Please enter your name',
  educationErrMsg: 'Please enter your educational background',
  occupationErrMsg: 'Please enter occupation',
  studydirectionErrMsg: 'Please enter the research direction',
  mainApplicationIndustryErrMsg: 'Please enter the application industry',
  localAreaErrMsg: 'Please enter your region',
  pictureUpload: 'picture upload',
  replace: 'replace',
  bind: 'bind',
  wechat: 'wechat',
  emailTitle: 'email',
  realNameAuthentication: 'realNameAuthentication',
  idCardErrMsg: 'Please enter the ID number',
  nameErrTypeMsg: 'The name only supports Chinese and is 2 to 4 characters long',
  idCardTypeErrMsg: 'ID number format error, please re-enter',
  frontofIDcard: 'Front of ID card',
  onthebackoftheIDcard: 'Back of ID card',
  submitapplication: 'Submit application',
  idCardEmptyFront: 'Please upload a photo of the front of your ID card',
  idCardEmptyEnd: 'Please upload a photo of the back of your ID card',
  companyErrMsg: 'Please enter the company name',
  companyTypeErrMsg: 'Company name only supports Chinese',
  creditCodeErrMsg: 'Please enter a unified social credit code',
  creditTypeCodeErrMsg: 'Only supports inputting uppercase letters and numbers',
  imgType: 'Only supports uploading JPG, PNG, and JPEG image formats',
  uploadyinye: 'Please upload a photo of your business license',
  uploadStudent: 'Please upload a photo of your student ID card',
  agreeReal: 'Please check the box to agree to the authenticity statement',
  okCompany: 'Enterprise certification successful',
  okStudent: 'Student authentication successful',
  schoolTimeErrMsg: 'Please choose your school time',
  companyAgree: 'Declaration of Authenticity'
}
export { userCenterZh, userCenterEn }
