const netDiskZh = {
  sharedStorage: '共享存储',
  objectStorage: '对象存储',
  cacheDisk: '缓存盘',
  used: '已使用',
  totalSpace: '总空间',
  bucketName: '桶名称',
  search: '搜索',
  share: '共享',
  people: '共享人数',
  subUserShare: '子用户共享',
  initTips: '首次使用网盘请进行初始化存储卷操作，点击"新建存储卷"即可完成创建',
  initVolumn: '新建存储卷',
  bucketID: '桶ID',
  region: '区域',
  storageType: '存储类型',
  storageDevice: '存储设备',
  sharedState: '共享状态',
  createTime: '创建时间',
  cacheDiskName: '缓存盘',
  cacheDiskID: '缓存盘ID',
  taskID: '实例ID',
  taskStatus: '实例状态',
  size: '大小',
  node: '计算节点',
  subUser: '子用户',
  releaseTime: '数据释放时间',
  operate: '操作',
  volumn: '存储卷',
  volumnId: '存储卷ID',
  fullPath: '实例中挂载路径',
  off: '已关机',
  on: '运行中',
  expansion: '扩容',
  sharing: '共享中',
  unshared: '未共享',
  others: '他人共享',
  fileType: '文件类型',
  fileName: '文件名称',
  back: '返回',
  returnPath: '返回上级路径',
  addFolder: '添加文件夹',
  download: '下载',
  del: '删除',
  cancel: '取消选择',
  fileUpload: '文件上传',
  updateTime: '更新时间',
  fileNameErr: '请输入文件夹名称',
  fileNameTypeErr: '文件夹名称中不能包含"/"',
  expandedSize: '免费容量',
  expandSize: '扩容大小',
  expandTime: '扩容时长',
  month: '月',
  asal: '按时按量',
  price: '价格',
  hour: '小时',
  discount: '折',
  endTime: '到期时间',
  totalPrice: '费用总计',
  netDiskTips: '网盘到期后文件将为您保存30天，30天后如容量超出免费空间将保留删除与之相关文件的权利',
  // taskTips: '实例关机后停止计费，重新开机需要重新扩容，实例关机48小时后，缓存盘数据将会清空'
  taskTips: '实例关机48小时后将清空缓存盘中的数据，请在关机前将重要数据存放到共享/对象存储中',
  successInit: '初始化成功',
  type: '类型',
  regionRule: '请选择区域',
  typeRule: '请选择类型',
  ipRule: '请选择IP',
  volumnName: '存储卷名称',
  addPermission: '添加权限',
  number: '序号',
  shareUserId: '共享用户ID',
  permission: '权限',
  remark: '备注',
  randw: '读写',
  ro: '只读',
  distributedStorage: '分布式存储',
  file: '文件',
  folder: '文件夹',
  expandSuccess: '扩容成功'
}

const netDiskEn = {
  sharedStorage: 'shared storage',
  objectStorage: 'object storage',
  cacheDisk: 'cache disk',
  used: 'used',
  totalSpace: 'total',
  bucketName: 'bucket name',
  search: 'search',
  people: 'people',
  share: 'share',
  subUserShare: 'sub user share',
  initTips: 'for the first time using a network disk, please initialize the storage volume and click on "new storage volume" to complete the creation',
  initVolumn: 'new storage volume',
  bucketID: 'bucket ID',
  storageType: 'type',
  storageDevice: 'device',
  sharedState: 'state',
  createTime: 'create time',
  cacheDiskName: 'cache disk name',
  cacheDiskID: 'cacheDisk ID',
  taskID: 'task ID',
  taskStatus: 'status',
  size: 'size',
  node: 'node',
  subUser: 'sub user',
  releaseTime: 'data release time',
  operate: 'operate',
  volumn: 'volumn',
  volumnId: 'volumn ID',
  fullPath: 'fullPath',
  off: 'off',
  on: 'on',
  expansion: 'expansion',
  sharing: 'sharing',
  unshared: 'unshared',
  others: 'others',
  fileType: 'file type',
  fileName: 'file name',
  back: 'back',
  returnPath: 'return path',
  addFolder: 'add folder',
  download: 'download',
  del: 'del',
  cancel: 'cancel',
  fileUpload: 'file upload',
  updateTime: 'update time',
  fileNameErr: 'please enter a folder name',
  fileNameTypeErr: 'The folder name cannot contain \'/\'',
  expandedSize: 'free capacity',
  expandSize: 'expand size',
  expandTime: 'expand time',
  month: 'month',
  asal: 'time/flow',
  price: 'price',
  hour: 'hour',
  discount: 'discount',
  endTime: 'end time',
  totalPrice: 'total price',
  netDiskTips: 'After the network disk expires, the files will be saved for 30 days. If the capacity exceeds the free space after 30 days, the right to delete related files will be reserved',
  // taskTips: 'After the task is shut down, billing will stop. Rebooting requires re expansion. After 48 hours of task shutdown, the cache disk data will be cleared'
  taskTips: 'The data in the cache disk will be cleared 48 hours after the task is powered off. Please store important data in the shared/Object storage before powering off',
  successInit: 'success init',
  region: 'region',
  type: 'type',
  regionRule: 'please select a region',
  typeRule: 'please select the type',
  ipRule: 'please select an IP',
  volumnName: 'volumn name',
  addPermission: 'add permission',
  number: 'num',
  shareUserId: 'share user id',
  permission: 'permission',
  remark: 'remark',
  randw: 'r&w',
  ro: 'r-o',
  distributedStorage: 'distributed storage',
  file: 'file',
  folder: 'folder',
  expandSuccess: 'expand success'
}

export {
  netDiskZh,
  netDiskEn
}
