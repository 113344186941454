import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_style_provider = _resolveComponent("a-style-provider")!
  const _component_ColorPicker = _resolveComponent("ColorPicker")!
  const _component_download_square = _resolveComponent("download-square")!
  const _component_global_uploader = _resolveComponent("global-uploader")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _ctx.$i18n.locale === 'zh-CN' ? _ctx.zhCN : _ctx.enUS
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_style_provider, { "hash-priority": "high" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }),
      (_ctx.store.$state.visible)
        ? (_openBlock(), _createBlock(_component_ColorPicker, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_download_square),
      _createVNode(_component_global_uploader)
    ]),
    _: 1
  }, 8, ["locale"]))
}