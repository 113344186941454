const delZh = {
  title: '系统提示',
  content: '是否确认',
  del: '删除',
  stop: '停用',
  enable: '启用',
  success: '成功',
  isDel: '是否确认删除',
  delSuccess: '删除成功',
  ok: '确认',
  cancel: '取消',
  all: '全部',
  operate: '操作',
  isRead: '是否标记为已读',
  readSuccess: '标记成功'
}
const delEn = {
  title: 'tip',
  content: 'are you sure',
  del: 'delete',
  stop: 'stop',
  enable: 'enable',
  success: 'success',
  isDel: 'are you sure delete',
  delSuccess: 'delete success',
  ok: 'ok',
  cancel: 'cancel',
  all: 'all',
  operate: 'operate',
  isRead: 'mark as read',
  readSuccess: 'mark successfully'
}

export {
  delZh,
  delEn
}
