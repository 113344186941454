const getStoredLanguage = (): any => {
  return localStorage.getItem('selectedLanguage')
}

const saveStoredLanguage = (language:string): void => {
  localStorage.setItem('selectedLanguage', language)
}

export {
  getStoredLanguage,
  saveStoredLanguage
}
