const subUserManagementZh = {
  subUserManagement: '子用户管理',
  domainNameManagement: '域名管理',
  domainNameLimit: '域名限制管理',
  subUserAccount: '子用户账号',
  status: '状态',
  search: '搜索',
  add: '添加',
  edit: '修改',
  del: '删除',
  enable: '启用',
  stop: '停用',
  isAdmin: '是否管理员',
  password: '密码',
  associated: '子用户关联域名',
  auth: '权限',
  readAndWrite: '读写',
  read: '只读',
  true: '是',
  false: '否',
  accountInput: '请输入子用户账号',
  accountLimit: '只能输入小写英文字母和数字，长度大于等于3小于20',
  passwordInput: '请输入子用户密码',
  concatInput: '请输入子用户关联域名',
  account: '账号',
  userName: '用户名',
  realmName: '域名',
  realmNameMessage: '域名信息',
  createBy: '创建人',
  createTime: '创建时间',
  realmNameMessageInput: '请输入域名信息',
  realmNameLimit: '域名格式错误',
  billingAccount: '计费账户',
  realmLimitCount: '域名限制数量',
  // realmLimitCount: '子用户数量',
  userLimitCount: '子用户限制个数',
  administrators: '管理员',
  admin: '管理员',
  user: '用户',
  userSelect: '请选择用户',
  realmLimitCountInput: '请输入域名限制个数',
  userLimitCountInput: '请输入子用户限制个数',
  operate: '操作',
  addSuccess: '添加成功',
  editSuccess: '修改成功'
}

const subUserManagementEn = {
  subUserManagement: 'sub user management',
  domainNameManagement: 'domain name management',
  domainNameLimit: 'domain name limit',
  subUserAccount: 'sub user account',
  status: 'status',
  search: 'search',
  add: 'add',
  edit: 'edit',
  del: 'del',
  enable: 'enable',
  stop: 'stop',
  isAdmin: 'is admin',
  password: 'password',
  associated: 'associated domain',
  auth: 'auth',
  readAndWrite: 'read and write',
  read: 'read',
  true: 'true',
  false: 'false',
  accountInput: 'please enter a sub user account',
  accountLimit: 'Only lowercase English alphabet and numbers can be entered, length3~20',
  passwordInput: 'please enter the sub user password',
  concatInput: 'Please select a sub user associated domain name',
  account: 'account',
  userName: 'user name',
  realmName: 'realm name',
  realmNameMessage: 'realm',
  createBy: 'create by',
  createTime: 'create time',
  realmNameMessageInput: 'please enter domain name information',
  realmNameLimit: 'domain name format error',
  billingAccount: 'billing account',
  realmLimitCount: 'realm limit count',
  userLimitCount: 'user limit count',
  admin: 'admin',
  user: 'user',
  userSelect: 'please select a user',
  realmLimitCountInput: 'please enter the number of domain name restrictions',
  userLimitCountInput: 'please enter the number of sub user restrictions',
  operate: 'operate',
  addSuccess: 'add success',
  editSuccess: 'edit success'
}
export {
  subUserManagementZh,
  subUserManagementEn
}
