import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49d68b1f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["st-btn", [_ctx.btnClassName, _ctx.ghostClassName]]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
    type: _ctx.htmlType
  }, [
    _renderSlot(_ctx.$slots, "icon", {}, undefined, true),
    _createElementVNode("span", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 10, _hoisted_1))
}