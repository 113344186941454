import { App } from 'vue'

export default (app: App<Element>) => {
  // 自定义组件
  app.directive('preventReClick', (el: any, binding) => {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 3000)
      }
    })
  })
}
