
import { computed, defineComponent, ref, onMounted } from 'vue'
import { downloadStore } from '@/store/download-process'

export default defineComponent({
  setup () {
    const download = downloadStore()
    const x = ref(0)
    const y = ref(0)
    const domRef = ref()
    onMounted(() => {
      // 监听浏览器刷新
      if (download.$state.progressPercent > 0 && download.$state.progressPercent <= 100) {
        window.addEventListener('beforeunload', (e) => {
          e.preventDefault()
          e.returnValue = ''
        })
      }
    })

    const progressText = computed<string>(() => {
      if (download.$state.progressPercent < 100) {
        return '正在下载'
      } else {
        return '下载完成'
      }
    })

    const movePosition = (event: MouseEvent) => {
      x.value = event.pageX - domRef.value.offsetWidth / 2
      y.value = event.pageY - domRef.value.offsetHeight / 2
    }

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', movePosition)
    }

    const handleMouseDown = () => {
      window.addEventListener('mousemove', movePosition)
    }

    const movePositionClassName = computed(() => {
      const obj = {
        top: y.value ? y.value + 'px' : '8%',
        left: x.value ? x.value + 'px' : '93%'
      }
      return obj
    })

    return {
      movePositionClassName,
      domRef,
      progressText,
      handleMouseUp,
      handleMouseDown,
      download
    }
  }
})
