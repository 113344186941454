import { App } from 'vue'
import SvgIcon from '@/components/svg-icon/SvgIcon.vue'
const req = require.context('./svg', false, /\.svg$/)

function requireAll (requireContext: any) {
  return requireContext.keys().map(requireContext)
}

requireAll(req)

export default {
  install: (vue: App): void => {
    vue.component('SvgIcon', SvgIcon)
  }
}
