import axios from 'axios'
import { message } from 'ant-design-vue'
import { setToken, getToken, removeToken } from './token'
import router from '@/router'

const instance = axios.create({
  baseURL: '/api'
})

instance.interceptors.request.use(config => {
  if (getToken()) {
    (Object as any).assign(config.headers, { 'Front-Token': getToken() })
  }
  return config
})

instance.interceptors.response.use(response => {
  if ((Object as any).hasOwn(response.headers, 'front-token')) {
    setToken(response.headers['front-token'])
  }
  const code = parseInt(response.data.code)
  if (code === 0) {
    return response.data
  } else if (code === -1) {
    message.error(response.data.msg)
    return Promise.reject(response.data.msg)
  } else if (code === -2) {
    message.error('您的登录状态已过期，请重新登录', 0.5).then(() => {
      removeToken()
      router.replace({
        path: '/login'
      })
      location.reload()
    })
  } else {
    return response
  }
})

export default instance
