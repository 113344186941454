function getToken (): string | null {
  return localStorage.getItem('token')
}

function setToken (token: string): void {
  localStorage.setItem('token', token)
}

function removeToken ():void {
  localStorage.removeItem('token')
}

export {
  getToken,
  setToken,
  removeToken
}
