import SparkMD5 from 'spark-md5'

export function generateMD5 (file: any, options: any) {
  const fileReader = new FileReader()
  const time = new Date().getTime()
  const blobSlice = File.prototype.slice
  const chunkSize = 20 * 1024 * 1024
  const chunks = Math.ceil(file.size / chunkSize) - 1
  let currentChunk = 0
  const spark = new SparkMD5.ArrayBuffer()
  const loadNext = () => {
    const start = currentChunk * chunkSize
    const end = start + chunkSize >= file.size ? file.size : start + chunkSize

    fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end))
  }

  loadNext()

  fileReader.onload = (e:any) => {
    spark.append(e.target.result)

    if (currentChunk < chunks) {
      currentChunk++
      loadNext()
      if (options.onProgress && typeof options.onProgress === 'function') {
        options.onProgress(currentChunk, chunks)
      }
    } else {
      const md5 = spark.end()

      // md5计算完毕
      if (options.onSuccess && typeof options.onSuccess === 'function') {
        options.onSuccess(md5)
      }

      console.log(
        `MD5计算完毕：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${file.size} 用时：${
          new Date().getTime() - time
        } ms`
      )
    }
  }

  fileReader.onerror = function () {
    console.log('MD5计算失败')
    if (options.onError && typeof options.onError === 'function') {
      options.onError()
    }
  }
}
