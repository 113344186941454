import { defineStore } from 'pinia'
export const useColorStore = defineStore('colorPicker', {
  state: () => {
    return {
      visible: false
    }
  },
  actions: {
    CloseColorPicker () {
      this.visible = false
    },
    SetColorVisible (value: boolean) {
      this.visible = value
    }
  },
  getters: {
    // 这里取名不可与state里的变量一致
    getColorVisible: state => state.visible
  }
})
