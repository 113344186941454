import { createI18n } from 'vue-i18n'
import { getStoredLanguage } from '@/utils/languageStorage'
import zh from './zh'
import en from './en'
const storedLocale = getStoredLanguage() || 'zh-CN'
const i18n = createI18n({
  legacy: false,
  locale: storedLocale,
  messages: {
    'zh-CN': zh,
    'en-US': en
  },
  globalInjection: true
})

export default i18n
