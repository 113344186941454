import { defineStore } from 'pinia'

const downloadStore = defineStore('download', {
  state: () => ({
    visible: false,
    progressPercent: 0
  }),
  actions: {
    setProcess (process: number) {
      this.progressPercent = process
    },
    setVisible () {
      this.visible = !this.visible
      this.progressPercent = 0
    }
  }
})

export {
  downloadStore
}
