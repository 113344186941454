const imageZh = {
  systemImage: '系统镜像',
  myImage: '我的镜像',
  imageName: '镜像名称',
  inputPlaceholder: '请输入',
  pythonEdition: 'Python',
  cudaEdition: 'Toolkit',
  search: '搜索',
  columns: {
    number: '序号',
    imageName: '镜像名称',
    address: '镜像地址',
    frameName: '框架名称',
    version: '版本',
    structure: '系统架构',
    state: '镜像状态',
    lockState: '锁定状态',
    createBy: '创建人',
    createTime: '创建时间',
    size: '大小',
    shareState: '共享状态',
    shareNum: '共享人数',
    subUser: '子用户',
    describe: '描述',
    updateTime: '更新时间',
    operate: '操作',
    shareUserId: '共享用户ID',
    permission: '权限',
    remark: '备注'
  },
  add: '添加',
  edit: '编辑',
  del: '删除',
  creating: '创建中',
  success: '已完成',
  error: '失败',
  unlock: '未锁定',
  lock: '已锁定',
  imageTag: '镜像标签',
  frameName: '框架名称',
  edition: '框架版本',
  lockStatus: '锁定状态',
  importWay: '镜像导入方式',
  localUpload: '本地上传',
  backgroundImport: '后台导入',
  fileUrl: '镜像地址',
  uoloadImage: '上传镜像',
  uploadText: '将文件拖到此处或点击上传',
  uploadCrtText: '将.crt文件拖到此处或点击上传',
  uploadKeyText: '将.key文件拖到此处或点击上传',
  uploadHint: '单次仅支持单个文件上传',
  status: '镜像状态',
  selectPlaceholder: '请选择',
  userId: '所属用户',
  share: '共享',
  subUserShare: '子用户共享',
  sharing: '共享中',
  otherShare: '他人共享',
  noShare: '未共享',
  addAuth: '添加权限',
  rw: '读写',
  re: '只读',
  cancel: '取 消',
  confirm: '确 定',
  structure: '系统架构',
  description: '描述',
  userName: '用户手机号',
  limitCount: '限制个数',
  rules: {
    imageName: '请输入镜像名称',
    imageTag: '请输入镜像标签',
    frameName: '请输入框架名称',
    edition: '请输入框架版本',
    lockStatus: '请选择锁定状态',
    importWay: '请选择导入方式',
    checkEdition: '请输入版本号',
    checkEditionRule: '版本号仅支持数字或小数',
    userId: '请选择用户',
    limitCount: '请输入限制个数',
    input: '请输入',
    imageNameLimit: '只可输入大小写字符、数字及特殊符号-_.',
    structure: '请输入系统架构'
  },
  state: '状态',
  nodel: '他人镜像不可删除'
}
const imageEn = {
  systemImage: 'system image',
  myImage: 'my image',
  imageName: 'imageName',
  inputPlaceholder: 'Please enter',
  pythonEdition: 'Python',
  cudaEdition: 'Toolkit',
  search: 'search',
  columns: {
    number: 'num',
    imageName: 'image name',
    address: 'address',
    frameName: 'frame name',
    version: 'version',
    structure: 'structure',
    state: 'state',
    lockState: 'lock state',
    createBy: 'create by',
    createTime: 'create time',
    size: 'size',
    shareState: 'share state',
    shareNum: 'share num',
    subUser: 'sub user',
    describe: 'describe',
    updateTime: 'update time',
    operate: 'operate',
    shareUserId: 'share user id',
    permission: 'permission',
    remark: 'remark'
  },
  add: 'add',
  edit: 'edit',
  del: 'delete',
  creating: 'Creating',
  success: 'Completed',
  error: 'Failed',
  unlock: 'Unlocked',
  lock: 'Locked',
  imageTag: 'imageTag',
  frameName: 'Frame name',
  edition: 'frameworkVersion',
  lockStatus: 'Lock status',
  importWay: 'Mirror import mode',
  localUpload: 'Local Upload',
  backgroundImport: 'Background Import',
  fileUrl: 'mirrorAddress',
  uoloadImage: 'Upload the image',
  uploadText: 'Drag file here or click Upload',
  uploadCrtText: 'Drag .crt file here or click Upload',
  uploadKeyText: 'Drag .key file here or click Upload',
  uploadHint: 'Supports only a single file upload at a time',
  status: 'Mirror status',
  selectPlaceholder: 'Please select',
  userId: 'owning user',
  share: 'Share',
  subUserShare: 'subuser share',
  sharing: 'In the sharing',
  otherShare: 'others share',
  noShare: 'not shared',
  addAuth: 'Add permission',
  rw: 'Read and write',
  re: 'Read only',
  cancel: 'cancel',
  confirm: 'confirm',
  userName: 'phoneNumber ',
  limitCount: 'limitNumber ',
  structure: 'structure',
  description: 'description',
  rules: {
    imageName: ' Please enter an image name ',
    imageTag: ' Please enter an image tag ',
    frameName: ' Please enter a frame name ',
    edition: ' Please enter frame version ',
    lockStatus: ' Please select lock status ',
    importWay: ' Please select import mode ',
    checkEdition: ' Please enter version number ',
    checkEditionRule: ' Version numbers are numeric or decimal only ',
    userId: ' Please select user ',
    limitCount: ' Please enter a limited number ',
    input: ' Please enter ',
    imageNameLimit: ' Only uppercase characters, numbers, and special symbols -_.',
    structure: 'Please enter a structure'
  },
  state: 'state',
  nodel: 'Other people\'s images cannot be deleted'
}
export { imageZh, imageEn }
